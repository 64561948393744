<template>
  <div class="user_order">
    <el-tabs v-model="active_name">
      <el-tab-pane label="SMT订单" name="order">
        <div class="my_orders_main">
          <div class="order_list_ul" v-if="order_show == true">
            <div
              class="order_list_li"
              v-for="(item, idx) in order_list"
              :key="idx"
            >
              <div class="li_title d-between">
                <div class="order_count">
                  订单号：<span>{{ item.serial_number }} </span>
                </div>
                <div class="time">
                  <span>{{ item.status }}</span>
                  {{ item.created_at }}
                </div>
              </div>

              <div class="li_bottom_main">
                <div class="li_list">
                  <div class="info_order d-between">
                    <div class="info_item" >
                      <div class="info_box">
                        <div class="item_type">
                          <b>单板生产数量：</b><span>{{ item.veneer_num }}pcs</span>
                        </div>
                        <div class="item_type">
                          <b>贴片层：</b><span>{{ item.smt_cover_type+'贴片' }}</span>
                        </div>
                        <div class="item_type">
                          <b>贴片：</b><span>{{item.smt_material_num}}种/pcs {{item.smt_welding_num}}焊盘/pcs</span>
                        </div>
                        <div class="item_type">
                          <b>插件：</b><span>{{item.dip_plug_material_num}}种/pcs {{item.dip_plug_welding_num}}焊点/pcs</span>
                        </div>
                      </div>
                    </div>
                    <div class="info_text">
                      <!--<p class="txt_num">x{{ item.num }}PCS</p>-->
                      <div class="pay_box" :class="item.status == '待确认' || item.status == '交易关闭'?'top':''">
                        <span class="pay_money">预估价</span>
                        <span class="pay_val">￥{{ item.forecast_money }}</span>
                      </div>
                      <div class="pay_box" v-if="item.status != '待确认' &&  item.status != '交易关闭'">
                        <span class="pay_money">应付款</span>
                        <span class="pay_val">￥{{ item.true_money }}</span>
                      </div>
                      <div class="pay_box" v-if="item.status != '待确认' && item.status != '交易关闭'">
                        <span class="pay_money">预付款</span>
                        <span class="pay_val">￥{{ item.advance_money }}</span>
                      </div>
                      <p class="pay_tip" v-if="item.status != '待确认' &&  item.status != '交易关闭'">应付款与预付款为双方确认之后的金额，无特殊原因不可更改</p>
                    </div>
                  </div>
                </div>
                <div class="info_bottom d-between">
                  <div>
                    <div class="address" v-if="item.address">
                        <p class="name">
                          <span>收货人：</span>{{ item.address.name }}
                          {{ item.address.tel }}
                        </p>
                        <p class="name">
                          <span>收货地址：</span>{{ item.address.district }}
                          {{ item.address.address }}
                        </p>
                    </div>
                    <div class="info_btn">
                      <div class="btns">
                          <div class="other" @click="refund(item)" v-if="item.status == '已付款'">申请退款</div>
                          <div class="other" @click="open(item)" v-if="item.status != '待确认'  && item.status != '待付款' && item.status != '交易关闭' && item.status != '已退款' && item.invoice_status == '未申请'" >申请开票</div>
                          <div class="sure"  @click="take(item)" v-if="item.status == '已发货'">确认收货</div>
                          <div class="pact"  @click="process(item)">查看加工信息</div>
                      </div>
                    </div>
                  </div>
                  <div class="info_sale">
                        <p class="sale_time" v-if="item.status != '已退款'  || item.status != '交易关闭'">交期5-14天</p>
                        <p class="sale_edit" @click="redact(item)" v-if="item.status == '待确认'">编辑</p>
                        <p class="sale_pay" @click="pay(item)" v-if="item.status == '待付款'">去付款</p>
                        <div class="sale_ele" v-if="item.status != '待确认'  && item.status != '待付款' && item.status != '交易关闭' && item.status != '已退款' && item.invoice_status == '已申请'">已申请{{item.invoice_type}}</div>
                        <el-popover
                          v-if="item.status == '已发货' || item.status == '已收货'"
                          popper-class="log_pop"
                          placement="bottom-start"
                          :width="200"
                          trigger="hover"
                          >
                              <template v-slot:reference> 
                                  <span class="sale_log" @mouseenter.stop="order_info(item)">查看物流</span>
                              </template> 
                          <div class="logi_box">
                              <div class="log_title">{{ express_company }} ：{{ express_number }} </div>
                              <ul class="log_list">
                                  <li v-for="(v, k) in logistics" :key="k">
                                      <span class="dian"></span>
                                      <span class="ctx">{{v.context}}</span>
                                  </li>
                              </ul> 
                          </div>
                      </el-popover>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bgc_empty" v-else>
            <img
              src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png"
              alt="百纳芯城"
            />
            <p>暂无数据</p>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 申请开票 -->
    <el-dialog
      custom-class="modal_open"
      v-model="open_show"
      :before-close="cancle"
    >
      <template v-slot:title>
        <div class="dialog-title">申请开票</div>
      </template>
      <div class="dialog-content">
        <div class="open_check d-flex">
          <span class="open_type">类型：</span>
          <el-radio-group
            class="d-flex"
            v-model="openCheck"
            @change="change_type"
          >
            <el-radio :label="'电子发票'">电子发票</el-radio>
            <el-radio :label="'增值税普票'">增值税普票</el-radio>
            <el-radio :label="'增值税专票'">增值税专票</el-radio>
          </el-radio-group>
        </div>

        <ul class="open_list">
          <li class="open_box">
            <span class="open_title">公司名称:</span>
            <span class="open_val">{{ openInfo.company || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">公司税号:</span>
            <span class="open_val">{{ openInfo.tax_number || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">单位地址:</span>
            <span class="open_val">{{ openInfo.address || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">电话号码:</span>
            <span class="open_val">{{ openInfo.tel || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">开户银行:</span>
            <span class="open_val">{{ openInfo.deposit_bank || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">银行账号:</span>
            <span class="open_val">{{ openInfo.bank_account || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">电子邮箱:</span>
            <span class="open_val">{{ openInfo.email || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">收 票 人:</span>
            <span class="open_val">{{ openInfo.get_name || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">联系电话:</span>
            <span class="open_val">{{ openInfo.get_tel || "/" }}</span>
          </li>
          <li class="open_box">
            <span class="open_title">收票地址:</span>
            <span class="open_val">{{ openInfo.get_address || "/" }}</span>
          </li>
        </ul>
        <p class="open_tip">*纸质发票需自付邮费，平台将会采用邮费到付的形式</p>
      </div>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button class="cancle" @click="cancle()">取 消</el-button>
          <el-button class="cancle" @click="edit()">编 辑</el-button>
          <el-button class="sure" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>


    <!-- 退款原因 -->
    <el-dialog
      custom-class="modal_refund"
      v-model="refund_show"
      :before-close="cancle_refund"
    >
      <template v-slot:title>
        <div class="dialog-title">退款原因</div>
      </template>
      <div class="dialog-content">
        <div class="open_check">
          <el-radio-group
            class=""
            v-model="refund_type"
            @change="change_refund"
          >
            <el-radio :label="'订单信息填写错误'">订单信息填写错误</el-radio>
            <el-radio :label="'不准备生产了'">不准备生产了</el-radio>
            <el-radio :label="'实际与平台描述不符'">实际与平台描述不符</el-radio>
            <el-radio :label="'价格不合适'">价格不合适</el-radio>
            <el-radio :label="'其他'">其他</el-radio>
          </el-radio-group>
        </div>
      </div>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button class="cancle" @click="cancle_refund()">取 消</el-button>
          <el-button class="sure" @click="submit_refund()">提 交</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 加工信息 -->
    <el-dialog
      custom-class="modal_process"
      v-model="process_show"
      :before-close="cancle_process"
    >
      <template v-slot:title>
        <div class="dialog-title">SMT加工信息</div>
      </template>
      <div class="dialog-content">
        <el-scrollbar height="460px">
          <div class="process_wrap">
            <div class="process_title">参数详情</div>
            <table class="process_table">
              <tr><td class="bg">单板生产数量</td><td>{{pinfo.veneer_num}}</td><td class="bg">PCB单板尺寸</td><td><span v-if="pinfo.veneer_length || pinfo.veneer_width">{{pinfo.veneer_length || 0}}cm x {{pinfo.veneer_width || 0}}cm</span></td></tr>
              <tr><td class="bg">烧录程序</td><td>{{pinfo.special_program}}</td><td class="bg">拼版数量</td><td>{{pinfo.panel_num}}</td></tr>
              <tr><td class="bg">SMT贴片</td><td>{{pinfo.smt_cover_type}}</td><td class="bg">功能测试</td><td>{{pinfo.function_test}}</td></tr>
              <tr><td class="bg">焊接插件料</td><td>{{pinfo.dip_plug_need}}</td><td class="bg">通电老化测试</td><td>{{pinfo.old_test}}</td></tr>
              <tr><td class="bg">需要拆板</td><td>{{pinfo.special_patch}}</td><td class="bg"></td><td></td></tr>
            </table>
            <div class="process_title">报价</div>
            <table class="process_table ">
              <tr><td class="bg">SMT贴片</td><td>￥{{pinfo.smt_patch_money}}</td><td class="bg">DIP插件</td><td>￥{{pinfo.dip_plug_money}}</td></tr>
              <tr><td class="bg">特殊工艺</td><td>￥{{pinfo.special_technique_money}}</td><td class="bg"></td><td></td></tr>
            </table>
            <div class="process_title">包装物流</div>
            <table class="process_table">
              <tr><td class="bg">包装物流费用</td><td>￥{{pinfo.packaging_money}}</td><td class="bg">标准包装辅材</td><td>气泡棉</td></tr>
            </table>
            <div class="process_title">相关文件</div>
            <table class="process_table">
              <tr><td class="bg">PCB</td><td>{{pinfo.pcb_custom ==2 ? '平台代采':'自己提供PCB'}}</td><td class="left"><a :href="pinfo.pcb_custom_file" :download="pinfo.pcb_custom_file_name">{{pinfo.pcb_custom_file_name}}</a></td></tr>
              <tr><td class="bg">BOM物料</td><td>{{pinfo.bom_custom ==2 ? '平台代采':'自己提供物料'}}</td><td class="left"><a :href="pinfo.bom_custom_file" :download="pinfo.bom_custom_file_name">{{pinfo.bom_custom_file_name}}</a></td></tr>
              <tr><td class="bg">SMT文件</td><td colspan="2" class="left"><a :href="pinfo.smt_file" :download="pinfo.smt_file_name">{{pinfo.smt_file_name}}</a></td></tr>
              <tr><td class="bg">丝印图</td><td colspan="2" class="left"><a :href="pinfo.print_file" :download="pinfo.print_file_name" target="_blank">{{pinfo.print_file_name}}</a></td></tr>
              <tr><td class="bg">钢网</td><td colspan="2"  class="left">{{pinfo.stencil ==2 ? '平台定制':'客户提供'}}</td></tr>
            </table>
            <div class="process_title" v-if="pinfo.note">备注</div>
            <div class="process_note"  v-if="pinfo.note">备注：{{pinfo.note}}</div>
            <div class="process_title">联系方式</div>
            <table  class="process_table">
              <tr><td class="bg">联系人</td><td>{{pinfo.connect_person}}</td><td class="bg">手机</td><td>{{pinfo.connect_phone}}</td></tr>
              <tr><td class="bg">邮箱</td><td colspan="3"  class="left">{{pinfo.connect_email}}</td></tr>
            </table>
          </div>
        </el-scrollbar>
      </div>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button class="sure" @click="cancle_process">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 预付款 -->
    <el-dialog
      custom-class="modal_pay"
      v-model="pay_show"
      :before-close="cancle_pay"
    >
      <template v-slot:title>
        <div class="dialog-title">预付款</div>
      </template>
      <div class="dialog-content">
        <div class="pay_wrap">
          <!--<div class="pay_price">￥{{advance_money}}</div>-->
          <div class="pay_price">￥{{advance_money}}</div>
          <div class="pay_box">
            <div class="box_main pay_choose d-center">
              <div
              class="pay_item"
              :class="{ active_pay: pay_type == 'ali_pay' }"
              @click="choose_pay('ali_pay')"
              >
              <img class="icon_img" src="@/assets/images/ali_icon.png" alt="" />
              支付宝支付
              <img
                  v-if="pay_type == 'ali_pay'"
                  class="active_img"
                  src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-131157.png"
                  alt=""
              />
              </div>

              <div
              class="pay_item"
              :class="{ active_pay: pay_type == 'wx_pay' }"
              @click="choose_pay('wx_pay')"
              >
              <img
                  class="icon_img wechat"
                  src="@/assets/images/wechat2.png"
                  alt="微信"
              />
              微信支付

              <img
                  v-if="pay_type == 'wx_pay'"
                  class="active_img"
                  src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-131157.png"
                  alt=""
              />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button class="other" @click="pay_show = false">取消</el-button>
          <el-button class="sure" @click="pay_order()">确认支付</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog custom-class="modal_pay" v-model="pay_diy" :before-close="payClose"> 
        <template v-slot:title>
            <div class="dialog-title">微信支付</div>
        </template>  
        <div class="dialog-content">
            <div class="erweima_img">
                <div class="svg_box" v-html="wx_pay_erweima"></div>
            </div>

            <p><img src="@/assets/images/paywx.png" alt="" />微信支付</p>
        </div>
    </el-dialog>



    <!--开票信息-->
    <addbill
        v-if="open_modal"
        :info="openInfo"
        :type="openType"
        @changeModal="changeBill"
    ></addbill>
  </div>
</template>

<script>
import addbill from '@/components/AddBill.vue'
import { getData, postData } from "@/api/user";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter} from "vue-router";
import { ElMessage } from "element-plus";

export default {
  name: "user_order",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter()

    let state = reactive({
      id:0,//当前订单id
      userid: store.state.userId,
      ali_oss: store.state.ali_oss,
      active_name: route.query.name || "order",

      // 订单
      order_list: [], 
      order_show: true,
      order_id: 0,

      // 开票
      open_show: false,
      open_modal: false,
      openInfo: null,
      openCheck: "电子发票",
      openType: "",

      // 退款
      refund_url: "",
      refund_type: "订单信息填写错误",
      refund_show: false,

      // 加工信息
      process_show: false,
      pinfo:{},

      // 支付
      advance_money:0,
      pay_show: false,
      pay_type: "ali_pay",
      pay_diy: false, // 微信支付弹框
      wx_pay_erweima: "", // 微信支付 二维码
      inter_login: "", // 轮询定时器

      // 物流
      log_show: false,
      logistics: [], // 物流订单 详情信息
      express_company: "物流公司",
      express_number: "123234234",

    });

    // 订单列表
    let get_order_list = () => {
      getData({
        url: `api/my_home/my_smt_order`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          state.order_list = data.data.order;
          state.order_show = data.data.order && data.data.order.length > 0;
        } else {
          ElMessage.error(data.data.msg);
        }
      });
    };
    get_order_list();



    // 开票
    let open = (v) => {
      state.order_id = v.id;
      getData({
        url: "api/invoices",
        data: {
          order_id: v.id,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          state.openInfo = data.data.info;
        } else {
          state.openInfo = null;
          ElMessage.error(data.data.msg);
        }
        if (state.openInfo != null) {
          state.open_show = true;
        } else {
          changeBill(true);
        }
      });
    };

    // 开票弹框
    let changeBill = (v, type, check) => {
      state.open_modal = v;
      state.openType = type;
      if (!v && check) {
        if (type == "edit") {
          state.open_show = true;
        } else {
          state.openCheck = check;
          submit();
        }
      }
    };

    // 开票类型
    let change_type = (v) => {
      state.openCheck = v;
    };

    let submit = () => {
      postData({
        url: `api/my_home/inovice_smt_order/${state.order_id}`,
        data: {
          invoice_id: state.openInfo.id,
          invoice_type: state.openCheck,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          ElMessage.success(data.data.msg);
          get_order_list();
          state.open_modal = false;
          state.open_show = false;
        } else {
          ElMessage.error(data.data.msg);
        }
      });
    };

    let cancle = () => {
      state.open_show = false;
    };

    let edit = () => {
      state.open_show = false;
      changeBill(true, "edit");
    };

    let redact = (v) => {
      
      router.push({
        path:'/smt_flow',
        query:{
          from:'user',
          id:v.id
        }
      })
      window.scrollTo(100,0)

    }

    // 退款
    let refund = (v) => {
      state.refund_url = `api/smt/refund_order/${v.id}`;
      state.refund_show = true;
    };

    let change_refund = (v) => {
      state.order_id = v.id;
      state.refund_type = v;
    };

    let cancle_refund = () => {
      state.refund_show = false;
      state.refund_type = "订单信息填写错误";
    };

    let submit_refund = () => {
      postData({
        url: state.refund_url,
        data: {
          refund_reason: state.refund_type,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          ElMessage.success(data.data.msg);
          state.refund_show = false;
          state.refund_type = "订单信息填写错误";
          get_order_list();
        } else {
          ElMessage.error(data.data.msg);
        }
      });
    };

    // 确认收货
    let take = (v) => {
      postData({
        url: `api/smt/confirm_order/${v.id}`,
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          ElMessage.success(data.data.msg);
          get_order_list();
        } else {
          ElMessage.error(data.data.msg);
        }
      });
    };

    // 加工信息
    let process = (v) => {
      state.process_show = true
      state.pinfo = v
    };

    let cancle_process = () => {
      state.process_show = false
    }

    // 编辑总订单
    let sale_edit = () => {

    }

    // 去付款
    let pay = (v) => {
      state.id = v.id
      state.advance_money = v.advance_money
      state.pay_show = true
    }

    // 支付方式
    let choose_pay = (type) => {
        state.pay_type = type;
    }

    let cancle_pay = () => {
      state.pay_show = false;
    }

    let payClose = () => {
        state.pay_diy = !state.pay_diy;
        if (!state.pay_diy) {
            clearInterval(state.inter_login);
        }
    }

    // 支付订单
    let pay_order = () => {


        if (state.pay_type === "ali_pay") {
            location.href = `https://chip.api.ebaina.com/api/smt/pay?pay_type=支付宝&order_id=${state.id}&user_id=${state.userid}`;
            return;
        }

        postData({
            url: `api/smt/pay`,
            data: {
              pay_type:'微信',
              order_id:state.id
            },
        }).then((res) => {
            let data = res.data;
            if (data.code === 200) {
                payClose();
                state.wx_pay_erweima = data.data.url;
                get_inter_login(data.data.order_id);
            } else if (data.code === 422) {
                ElMessage.error(data.data.msg);
            } else {
                ElMessage.error(data.data.msg);
            }
        });
    }

    // 获取支付状态
    let get_inter_login = (order_id) => {
        state.inter_login = setInterval(() => {
            getData({
                url: `api/smt/detail/${order_id}`,
                data: {},
            }).then((res) => {
                let data = res.data
                if(data.data && data.code == 200) {
                  let info = data.data.order
                  if (info.status == "已付款") {
                      clearInterval(state.inter_login);
                      state.pay_diy = false
                      state.pay_show = false
                      ElMessage.success('支付成功，页面正在刷新')
                      setTimeout(() => {
                          location.reload()
                          window.scrollTo(100,0)
                      }, 2000);
                  }
                }
            });
        }, 3000);
    }

    // 物流信息
    let order_info = (v) => {
        state.logistics = [];

        getData({
            url: `api/my_home/smt_logistics/${v.id}`,
            data: {},
        }).then((res) => {
            let data = res.data;
            if (data.code === 200) {
                let info = data.data.info;
                if (info.code == 200) {
                    state.express_company = info.express.company;
                    state.express_number = info.express.number;
                    state.logistics = info.data;
                }
            } else if (data.code === 422) {
                ElMessage.error(data.data.msg);
            } else {
                ElMessage.error(data.data.msg);
            }
        });
    };

    return {
      ...toRefs(state),
      get_order_list,
      submit,
      process,
      take,
      cancle,
      edit,
      open,
      change_type,
      changeBill,
      refund,
      change_refund,
      submit_refund,
      cancle_refund,
      sale_edit,
      cancle_process,
      pay,
      choose_pay,
      payClose,
      pay_order,
      redact,
      cancle_pay,
      order_info
    };
  },
  components: {
    addbill
  },
};
</script>

<style lang="less" scoped>
.user_order {
  position: relative;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

  /deep/ .el-tabs__nav-wrap {
    .el-tabs__nav-scroll {
      height: 100%;
    }
    .el-tabs__active-bar {
      background-color: #1c46b7;
    }
    .el-tabs__item {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .el-tabs__item.is-active {
      color: #333333;
      font-size: 15px;
    }
    .el-tabs__item:hover {
      color: #999;
    }
    .el-tabs__item.is-active:hover {
      color: #333;
    }
  }
}

/* SMT订单 */
.my_orders_main {
  position: relative;

  .order_list_ul {
    .order_list_li {
      margin-bottom: 30px;

      .li_title {
        padding: 14px 44px 14px 14px;
        font-size: 12px;
        color: #666666;
        border: 1px solid #eaeaea;
        border-bottom: none;

        .order_count {
          span {
            font-size: 13px;
            color: #333333;
          }
        }

        .time {
          span {
            color: #1c46b7;
            margin-right: 30px;
          }
        }
      }

      .li_bottom_main {
        border: 1px solid #eaeaea;
      }


      .info_order {
        padding: 8px 44px 14px 14px;
        width: 100%;
        border-bottom: 1px solid #EAEAEA;
        margin-bottom: 9px;

        .info_item {
          cursor: pointer;
          display: flex;

          .info_box {
            width: 275px;
            font-size: 13px;
            line-height: 18px;
            background:#f5f5f5;
            padding: 8px 26px 8px 12px;

            .item_type {
              margin: 0 0 5px 0;
              color: #999;
              b {
                display: inline-block;
                font-weight: normal;
                width: 100px;
              }
              span {
                color: #333;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .border_btm_none {
        border-bottom: 1px solid #eaeaea !important;
        margin-bottom: 12px;
      }


      .info_text {
        text-align: right;
        font-size: 12px;
        color: #666;
        line-height: 17px;
      
        .txt_num {
          margin: 8px 0  6px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #666666;
        }

        .pay_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 6px;
          color: #333;
          .pay_money {
            min-width: 60px;
            color: #666;
          }

          .pay_val {
            min-width: 90px;
            color: #333;
          }

          .pay_tip {
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
            color: #999999;
          }
        }
        .pay_box.top {
          margin-top: 36px;
        }
      }
      .info_bottom {
        align-items: flex-start;
        padding: 0 44px 14px 14px;
        .address {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #333333;
          margin-bottom: 15px;
          .name {
            margin-bottom: 6px;
            span {
              display: inline-block;
              width: 70px;
              color: #999999;
            }
          }
          
        }
        .info_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .btns {
            display: flex;
            align-items: center;
          }

          .other,
          .sure {
            font-size: 12px;
            line-height: 17px;
            color: #666666;
            padding: 6px 12px;
            border: 1px solid #8e8e8e;
            border-radius: 2px;
            margin-right: 12px;
            cursor: pointer;
          }
          .sure {
            background: #316DFA;
            color: #fff;
            border: 1px solid #316DFA;
          }
          .pact {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #3385F6;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .info_sale {
          text-align: right;
          font-size: 12px;
          line-height: 17px;
          color: #666;
          font-weight: 400;
          .sale_time {
            color: #333;
            margin-bottom: 6px;
          }
          .sale_status {
            color: #999999;
          }
          .sale_edit {
            color: #3385F6;
            text-decoration: underline;
            cursor:pointer;
          }
          .sale_pay {
              color: #FF7F00;
              text-decoration: underline;
              cursor:pointer;
          }
          .sale_ele {
            color: #333;
            margin-bottom: 6px;
          }
          .sale_log {
            color:#3385F6;
          }
        }
      }     
    }
  }
}

.bgc_empty {
  padding-top: 100px;
  text-align: center;

  img {
    width: 128px;
    height: 142px;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
  }
}

.open_check {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.open_type {
  font-size: 13px;
  line-height: 18px;
  color: #666;
  width: 65px;
}

.open_list {
  .open_box {
    display: flex;
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 18px;
    color: #666666;
  }
  .open_title {
    width: 75px;
  }
  .open_val {
    flex: 1;
    color: #333;
  }
}

.open_tip {
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  margin-bottom: 50px;
}
</style>

<style lang="less">
.modal_pay {
    width: 345px;
    height: 320px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body {
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-content {
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        padding-bottom: 20px;
        color: #666;

        span {
            color: #ef0016;
        }

        .erweima_img {
            margin: auto;
            padding: 10px;
            width: 166px;
            height: 166px;
            border: 1px solid #629856;
            overflow: hidden;
            margin-top:11px;

            .svg_box {
                svg {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            font-size: 16px;
            color: #666;
            line-height: 1;

            img {
                margin-right: 7px;
                width: 26px;
                height: 22px;
            }
        }
    }
}
.modal_open {
  width: 500px;
  height: 590px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  .el-dialog__header {
    padding: 18px 24px 24px;
    .dialog-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0 24px;
  }
  .el-dialog__footer {
    padding: 0 24px 18px;
  }

  .dialog-footer {
    .el-button {
      width: 68px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      padding: 0;
      background: #fafafa;
    }
    .sure {
      background: #1c46b7;
      color: #fff;
      border: 1px solid #1c46b7;
    }
  }

  .el-radio {
    height: auto;
  }

  .el-radio__input .el-radio__label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #666666;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #013eaa;
    background: #013eaa;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #333;
    font-size: 13px;
  }
}
.modal_refund {
  width: 521px;
  height: 213px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;

  .el-dialog__header {
    padding: 18px 24px 24px;
    .dialog-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0 24px;
  }
  .el-dialog__footer {
    padding: 0 24px 18px;
  }

  .dialog-footer {
    .el-button {
      width: 68px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      padding: 0;
      background: #fafafa;
    }
    .sure {
      background: #1c46b7;
      color: #fff;
      border: 1px solid #1c46b7;
    }
  }

  .el-radio {
    height: auto;
    margin-bottom: 26px;
  }

  .el-radio__input .el-radio__label,
  .el-radio__label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #666666;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #013eaa;
    background: #013eaa;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #333;
    font-size: 13px;
  }

  .open_check {
    margin-bottom: 0;
  }
}

.modal_process {
  width: 620px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;

  .el-dialog__header {
    padding: 18px 24px 24px;
    .dialog-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0 24px;
  }
  .el-dialog__footer {
    padding: 0 24px 18px;
  }

  .dialog-footer {
    .el-button {
      width: 68px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      padding: 0;
      background: #fafafa;
    }
    .sure {
      background: #1c46b7;
      color: #fff;
      border: 1px solid #1c46b7;
    }
  }

  .process_wrap {
    padding-right: 10px;
  }

  .process_title {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #333333;
    margin-bottom: 23px;
  }

  .process_table {
    border:1px solid #e8e8e8; 
    border-collapse:collapse; 
    table-layout: fixed;
    width: 100%;
    font-size: 13px;
    color: #333;
    font-weight: 400;
    margin-bottom: 23px;
    td {
      height: 36px;
      border:1px solid #E8E8E8; 
      text-align: center;
      padding:0 16px;
    }
    td.left {
      text-align: left;
    }
    td.bg {
      width: 115px;
      background: #f5f5f5;
    }
    a {
      color: #316DFA;
      text-decoration: underline!important;
    }
  }

  .process_note {
    padding: 16px 15px 12px;
    border: 1px solid #E8E8E8;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #333333;
    margin-bottom: 23px;
  }
}

.modal_pay {
  width: 470px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;

  .el-dialog__header {
    padding: 18px 24px 24px;
    .dialog-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0 24px;
  }
  .el-dialog__footer {
    padding: 0 24px 18px;
  }

  .dialog-footer {
    .el-button {
      width: 68px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      padding: 0;
      background: #fafafa;
    }
    .sure {
      background: #1c46b7;
      color: #fff;
      border: 1px solid #1c46b7;
    }
  }

  .pay_price {
    height: 72px;
    background: #F5F6F8;
    border-radius: 4px;
    font-size: 24px;
    font-weight: 500;
    line-height: 70px;
    text-align:center;
    color: #333;
    margin-bottom: 14px;
  }

  .pay_choose {
    margin-bottom: 20px;
    .pay_item {
      position: relative;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 70px;
      font-size: 16px;
      font-weight: 700;
      color: #333333;
      border: 1px solid #eaeaea;
      flex-shrink: 0;
      cursor: pointer;

      .icon_img {
          margin-right: 11px;
          width: 28px;
          height: 28px;
      }

      .active_img {
          position: absolute;
          top: 0;
          right: 0;
          width: 46px;
          height: 46px;
      }
    }
    .pay_item:last-child {
      margin-right: 0;
    }

    .active_pay {
      background: #F5FAFF;
      border: 2px solid #316DFA;

      .active_img {
          display: block !important;
      }
    }
}
}
</style>

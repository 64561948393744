
<!-- 我的主页架子 -->
<template>
<div class="user">
    <topnav active="user" @get="get"></topnav>
    <topmenu active="user"></topmenu>

    <div class="user_wrap public_width d-flex">
        <div class="left">
            <el-menu class="left_menu" :default-active="open_id">
                <el-menu-item :index="v.id" v-for="v in menu" :key="v.id" @click="change_menu(v)">
                    <div class="title_icon" v-html="v.icon"></div>
                    <span >{{ v.text }}</span>
                </el-menu-item>
            </el-menu>
        </div>

        <component class="right" :is="open_compontent" @changeTemp="change_menu($event)"></component>

    </div>

    <foot active="user"></foot>
    <toolbar />
</div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import UserIndex from "./User_index";
import UserAddress from "./User_address";
import UserInvoice from "./User_invoice";
import UserOrder from "./User_order";
import SmtOrder from "./Smt_order";
import UserCoupon from "./User_coupon";

import UserMsg from "./User_msg";
import { useStore } from "vuex";
import {toRefs, reactive} from 'vue';
import { useRouter, useRoute } from "vue-router";

export default {

    setup() {
        const store = useStore()
        const router = useRouter();
        const route = useRoute();
        store.commit('setCount');

        let state = reactive({
            userid: store.state.userId,
            open_id: "1", // 默认选中菜单
            open_compontent: 'UserIndex',
            menu: [
                {
                    id: "1",
                    text: "账号信息",
                    icon: '<i class="iconfont icon-s-zhxx" style="font-size: 17px;"></i>',
                    template: 'UserIndex',
                },
                {
                    id: "2",
                    text: "收货地址",
                    icon: '<i class="iconfont icon-s-shdz" style="font-size: 19px;"></i>',
                    template: 'UserAddress',
                },
                {
                    id: "3",
                    text: "发票管理",
                    icon: '<i class="iconfont icon-s-fpxx" style="font-size: 18px;"></i>',
                    template: 'UserInvoice',
                },
                {
                    id: "4",
                    text: "商品订单",
                    icon: '<i class="iconfont icon-s-spdd" style="font-size: 18px;"></i>',
                    template: 'UserOrder',
                },
                {
                    id: "5",
                    text: "SMT订单",
                    icon: '<i class="iconfont icon-s-smtdd" style="font-size: 18px;"></i>',
                    template: 'SmtOrder',
                },
                {
                    id: "6",
                    text: "优惠券",
                    icon: '<i class="iconfont icon-s-yhj" style="font-size: 18px;"></i>',
                    template: 'UserCoupon',
                },
                
                {
                    id: "7",
                    text: "我的消息",
                    icon: '<i class="iconfont icon-s-wdxx" style="font-size: 19px;"></i>',
                    template: 'UserMsg',
                },
            ],
        })

        // 修改显示模板
        let change_menu = (v) => {
            state.open_compontent = v.template;
            state.open_id = v.id;

            router.push({
                path: "/user",
                query: {
                    temp: v.template,
                    id: v.id,
                },
            });
            window.scrollTo(100,0)
        }

        // created
        if (state.userid) {
            if (route.query.temp) {
                state.open_compontent = route.query.temp;
                state.open_id = route.query.id;
            }
        } else {
            router.push({ path: "/" });
            window.scrollTo(100,0)
        }


        let get = (v) => {
            state.open_compontent = v.temp;
            state.open_id = v.id;
        }

        return {
            ...toRefs(state),
            change_menu,
            get
        }
    },
    components: {
        topnav,
        topmenu,
        foot,
        UserIndex,
        UserAddress,
        UserInvoice,
        SmtOrder,
        UserCoupon,
        UserOrder,
        UserMsg,
        toolbar
    },
};
</script>

<style lang="less" scoped>
    .user {
        padding: 154px 0 250px;

        .user_wrap {
            padding: 20px 0;
        }

        .title_icon {
            margin-right: 15px;
            line-height: 1;
            font-size: 12px;
        }

        .left {
            text-align: center;
            background: #0B2569;
            min-height: 700px;
            width: 200px;
            margin-right: 24px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

            .left_menu {
                border: none;
                padding: 30px 0 0;
                background-color: transparent;
                .el-menu-item {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 40px !important;
                    background-color: transparent;
                    margin-bottom: 10px;
                    border-left: 4px solid transparent;
                    color: #BEBECC;
                    font-size: 14px;
                    &:hover {
                        background: #1C46B7;
                        border-left: 4px solid #1C46B7;
                    }
                }

                .el-menu-item.is-active {
                    background: #1C46B7;
                    border-left: 4px solid #559BFF; 
                    .iconfont {
                        font-size: 15px;
                        //  margin: 0 18px 0 0;
                    }
                }
            }
        }

        .right {
            flex: 1;
        }
    }

</style>
<!-- 我的订单 -->
<template>
    <div class="user_coupon">
        <el-tabs v-model="active_name" @tab-click="change_tab">
            <el-tab-pane label="可用优惠券" name="able">
                <div v-if="in_show || before_show">
                    <ul class="coupon_list" v-if="in_show">
                        <li :class="item.coupons.use_target == '会员' ? 'vip' : ''" v-for="(item, idx) in in_list" :key="idx" @click="to_home()">
                            <div class="coupon_box">
                                <div class="coupon_top">
                                    <p class="name"><i class="vip_icon iconfont icon-VIP" v-if="item.coupons.use_target == '会员'"></i>  {{item.name || '优惠券'}}</p>
                                    <div class="money d-flex">
                                        <div class="price">￥<span>{{item.dec_money}}</span></div>
                                        <div class="cond">满{{item.threshold_money}}可用</div>
                                    </div>
                                    <div class="to d-center">去使用</div>
                                </div>
                                <div class="coupon_bottom">
                                    <p class="font time">有效日期：<span class="val">{{item.start_time}}-{{item.end_time}}</span></p>
                                    <p class="font obj">适用对象：<span class="val">{{ item.coupons.use_target }}</span></p>
                                    <p v-if="item.apply.length <= 14" class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    <el-tooltip popper-class="user_pop" v-else class="item" effect="dark" :content="item.apply" placement="bottom-start">
                                        <p class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="before_box" v-if="before_show">
                        <div class="d-center" v-if="before_show"><span class="coupon_before">未生效优惠券</span><p class="coupon_line"></p></div>
                        <ul class="coupon_list after_list" v-if="before_show">
                            <li :class="item.coupons.use_target !== '会员' ? 'vip' : ''" v-for="(item, idx) in before_list" :key="idx">
                                <div class="coupon_box">
                                    <div class="coupon_top">
                                        <p class="name"><i class="vip_icon iconfont icon-VIP" v-if="item.coupons.use_target == '会员'"></i>  {{item.name || '优惠券'}}</p>
                                        <div class="money d-flex">
                                            <div class="price">￥<span>{{item.dec_money}}</span></div>
                                            <div class="cond">满{{item.threshold_money}}可用</div>
                                        </div>
                                        <div class="to d-center">未生效</div>
                                    </div>
                                    <div class="coupon_bottom">
                                        <p class="font time">有效日期：<span class="val">{{item.start_time}}-{{item.end_time}}</span></p>
                                        <p class="font obj">适用对象：<span class="val">{{ item.coupons.use_target }}</span></p>
                                        <p v-if="item.apply.length <= 14" class="font apply">适用范围：<span class="val">{{ item.apply }}
                                            </span></p>
                                        <el-tooltip popper-class="user_pop" v-else class="item" effect="dark" :content="item.apply" placement="bottom-start">
                                            <p class="font apply">适用范围：<span class="val">{{ item.apply }}
                                            </span></p>
                                        </el-tooltip>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="bgc_empty" v-else>
                    <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="百纳芯城"/>
                    <p>暂无数据</p>

                </div>

            </el-tab-pane>

            <el-tab-pane label="不可用优惠券" name="post">
            <!--过期、不适用、已使用 -->
                <ul class="coupon_list after_list" v-if="after_show">
                    <li :class="item.coupons.use_target == '会员' ? 'vip' : ''" v-for="(item, idx) in after_list" :key="idx">
                        <div class="coupon_box">
                                <div class="coupon_top">
                                    <p class="name"><i class="vip_icon iconfont icon-VIP" v-if="item.coupons.use_target == '会员'"></i>  {{item.name || '优惠券'}}</p>
                                    <div class="money d-flex">
                                        <div class="price">￥<span>{{item.dec_money}}</span></div>
                                        <div class="cond">满{{item.threshold_money}}可用</div>
                                    </div>
                                    <div class="to d-center">
                                        <p v-if="item.list == 1">已过期</p>
                                        <p v-else-if="item.list == 2">已使用</p>
                                        <p v-else class="target d-center">不适用</p>
                                    </div>
                                </div>
                                <div class="coupon_bottom">
                                    <p class="font time">有效日期：<span class="val">{{item.start_time}}-{{item.end_time}}</span></p>
                                    <p class="font obj">适用对象：<span class="val">{{ item.coupons.use_target }}</span></p>
                                    <p v-if="item.apply.length <= 14" class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    <el-tooltip popper-class="user_pop" v-else class="item" effect="dark" :content="item.apply" placement="bottom-start">
                                        <p class="font apply">适用范围：<span class="val">{{ item.apply }}
                                        </span></p>
                                    </el-tooltip>
                                </div>
                        </div>
                    </li>
                </ul>
                <div class="bgc_empty" v-else>
                    <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="百纳芯城"/>
                    <p>暂无数据</p>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getData } from "@/api/user";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
    name: "user_coupon",
    setup() {
        const store = useStore();
        const router = useRouter()

        let state = reactive({
            ali_oss: store.state.ali_oss,
            active_name: 'able',
            in_list: [], 
            in_show: false,
            before_list: [],
            before_show: false,
            after_list: [],
            after_show: false,
        });

        let get_list = () => {
            getData({
                url: `api/my_home/my_coupon`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let list = data.data.user_coupons;
                    state.in_list = list.in_time_coupons
                    state.in_show = state.in_list.length > 0
                    state.before_list = list.before_time_coupons;
                    state.before_show = state.before_list.length > 0

                    let in_list = list.in_time_coupons
                    if(in_list && in_list.length > 0) {
                        in_list.forEach((v) => {
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }

                    let before_list = list.before_time_coupons
                    if(before_list && before_list.length > 0) {
                        before_list.forEach((v) => {
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }


                    
                    let after = list.after_time_coupons
                    if(after && after.length > 0) {
                        after.forEach((v) => {
                            v.list  = 1
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }

                    let has = list.has_use_coupons
                    if(has && has.length > 0) {
                        has.forEach((v) => {
                            v.list  = 2
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }

                    let target = list.target_forbid_coupons
                    if(target && target.length > 0) {
                        target.forEach((v) => {
                            v.list  = 3
                            let str = ''
                            v.coupons.category_name.forEach((a,b)=>{
                                str += b < v.coupons.category_name.length -1 ? a+'/': a
                            })
                            v.apply = str
                        })
                    }
                    
                    state.after_list = after.concat(has).concat(target)
                    
                    state.after_show = state.after_list.length > 0

                
                }else {
                    ElMessage.error(data.data.msg);

                }
            });
        };
        get_list();

        let change_tab = () => {
            if(state.active_name == 'able') {
                get_list()
            }else {
                get_list()
            }
        }
        let to_home = () => {
            router.push({ path: "/" });
            window.scrollTo(100,0)
        }


        return {
            ...toRefs(state),
            get_list,
            change_tab,
            to_home,
        };
    },
    
};
</script>

<style lang="less" scoped>
.user_coupon {
    padding: 30px 40px;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

    /deep/ .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
            height: 100%;
        }
        .el-tabs__active-bar {
            background-color: #1c46b7;
        }
        .el-tabs__item {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
        }
        .el-tabs__item.is-active {
            color: #333333;
            font-size: 15px;
        }
        .el-tabs__item:hover {
            color: #999;
        }
        .el-tabs__item.is-active:hover {
            color: #333;
        }
    }
}

.coupon_list {
    li {
        display: inline-block;
        height: 154px;
        width: 270px;
        margin: 30px 20px 0 0;
        color: #fff;
        cursor: pointer;
        border: 1px solid #E6E6E6;
        .coupon_box {
            height: 100%;
            width: 100%;
        }
        .coupon_top {
            position: relative;
            width: 100%;
            padding: 9px 15px 6px;
            height: 86px;
            background: #559BFF;
        }
        .name {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #FFFFFF;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        }
        .money {
            align-items: center;
            justify-content: flex-start;
        }
        .price {
            font-size: 14px;
            font-weight: 600;
            line-height: 46px;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
            margin-right: 20px;
            span {
                font-size: 30px;
            }
        }
        .cond {
            height: 30px;
            padding: 6px;
            border: 1px solid #FFFFFF;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 600;
            line-height: 17px;
            color: #FFFF;
        }
        .coupon_bottom {
            padding: 4px 7px 7px 13px;
        }
        .font {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #999999;
            margin-bottom: 3px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .val {
                color: #666666;
            }
        }
        .to {
            position: absolute;
            right: 15px;
            top: 9px;
            width: 28px;
            height: 70px;
            border: 1px solid #FFFFFF;
            opacity: 0.8;
            border-radius: 15px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #FFFFFF;
            text-align:center;
            
        }
    }

    li.vip {
        .coupon_top {
            position: relative;
            width: 100%;
            padding: 9px 15px 6px;
            height: 86px;
            background: linear-gradient(180deg, #EFC195 0%, #B47B4F 100%);
        }
    }
    li:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }

}
.after_list {
    li {
        cursor: default;
        .coupon_top {
            background: #D9D9D9;
        }
    }
    li.vip {
        .coupon_top {
            background: linear-gradient(180deg, #EAEAEA 0%, #B4B4B4 100%);
        }
    }
}

.before_box {
    margin-top: 40px;
}

.coupon_before {
    font-size: 15px;
    line-height: 21px;
    color: #999999;
}

.coupon_line {
    border-top: 1px solid #E9E9E9;
    width: 100%;
    flex: 1;
}

.bgc_empty {
    padding-top: 100px;
    text-align: center;

    img {
        width: 128px;
        height: 142px;
        margin-bottom: 20px;
    }

    p {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #333333;
    }
}
</style>

<style>
.el-popper.is-dark.user_pop {
    width: 330px!important;
    line-height: 16px;
}
</style>
<template>
  <div class="user_order">
    <el-tabs v-model="active_name">
      <el-tab-pane label="发票管理" name="order">
        <div class="my_orders_main">
          <div class="smt_invoice" v-if="addr_show">
            <div class="warm_prompt">温馨提示：请确保所填开票信息与贵公司税务登记证一致。</div>
            <el-form
                :model="form"
                ref="form_invoice"
                :rules="invoice_rules"
                label-width="100px"
            >
                <el-form-item  label="公司名称:" prop="company">
                <el-input :disabled="addr_bol" v-model="form.company"></el-input>
                </el-form-item>

                <el-form-item label="公司税号:" prop="tax_number">
                <el-input :disabled="addr_bol" v-model="form.tax_number"></el-input>
                </el-form-item>

                <el-form-item label="单位地址:">
                <el-input :disabled="addr_bol" v-model="form.address"></el-input>
                </el-form-item>

                <el-form-item label="电话号码:">
                <el-input :disabled="addr_bol" v-model="form.tel"></el-input>
                </el-form-item>

                <el-form-item label="开户银行:">
                <el-input :disabled="addr_bol" v-model="form.deposit_bank"></el-input>
                </el-form-item>

                <el-form-item label="银行账号:">
                <el-input :disabled="addr_bol" v-model="form.bank_account"></el-input>
                </el-form-item>

                <el-form-item label="电子邮箱:" prop="email">
                <el-input :disabled="addr_bol" v-model="form.email"></el-input>
                </el-form-item>

                <div class="confirm_btns" v-if="addr_bol">
                    <el-button @click="edit()">编辑</el-button>
                </div>
                <div class="confirm_btns" v-else>
                    <el-button @click="cancel()">取消</el-button>
                    <el-button @click="resetForm()">重置</el-button>
                    <el-button class="sure" type="primary" @click="submitForm()">保存</el-button>
                </div>

            </el-form>
          </div>
          <div class="bgc_empty" v-else>
            <div class="empty_wrap">
              <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png"
                alt="百纳芯城"
              />
              <p>暂无发票信息</p>
              <el-button class="add" @click="add()">添加发票信息</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getData,postData } from "@/api/user";
import { toRefs, reactive,ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";

export default {
  name: "user_invoice",
  setup() {
    const store = useStore();
    const route = useRoute();
    const form_invoice = ref(null)


    var checkDuty = (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请输入公司税号"));
        }
        const duty = /^[A-Za-z0-9]+$/;
        if (duty.test(value)) {
            callback();
        } else {
            return callback(new Error("公司税号税号只能是数字和字母！"));
        }
    };

    var checkEmail = (rule, value, callback) => {
        if (!value) {
            return callback(new Error("请输入邮箱地址"));
        }
        let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
        if (reg.test(value)) {
            callback();
        } else {
            return callback(new Error("请输入正确的邮箱！"));
        }
    }

    let state = reactive({
      ali_oss: store.state.ali_oss,
      active_name: route.query.name || "order",

      addr_show: false,
      addr_len: false,
      addr_bol: true,
      info: {},
      invoice_rules: {
        company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        tax_number: [{ required: true, validator: checkDuty, trigger: "blur" }],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
      },
      form: {
        company: "",
        tax_number: "",
        address: "",
        tel: "",
        deposit_bank: "",
        bank_account: "",
        email: "",
      }
    });

    
    //  发票信息
      let get_invoice = () => {
          getData({
              url: "api/invoices",
              data: {},
          }).then((res) => {
              let data = res.data;
              if (data.code === 200) {
                  state.info = data.data.info
                  state.addr_show = data.data.info ? true : false
                  state.addr_len = data.data.info ? true : false
                  state.addr_bol = data.data.info ? true : false
                  state.form = data.data.info
              }else {
                  ElMessage.error(data.data.msg);
              }
          });
      };
      get_invoice()


    // 新增
    let add = () => {
      state.addr_show = true
    }

    // 编辑
    let edit = () => {
      state.addr_bol = false
    }

    // 取消
    let cancel = () => {
      if(state.addr_len) {
        state.addr_bol = true
        state.form = state.info
      }else {
        state.addr_show = false
      }
      
    }

    // 重置
    let resetForm = () => {
        form_invoice.value.resetFields();
        state.form = {};
    }

    // 保存
    let submitForm = () => {
      form_invoice.value.validate((valid) => {
        if (valid) {
          let url = `api/invoices`;
          let params = {
              company: state.form.company,
              tax_number: state.form.tax_number,
              address: state.form.address,
              tel: state.form.tel,
              deposit_bank: state.form.deposit_bank,
              bank_account: state.form.bank_account,
              email: state.form.email,
          };
          postData({
              url: url,
              data: params,
          }).then((res) => {
              let data = res.data;
              if (data.code === 200) {
                  ElMessage.success(data.data.msg);
                  cancel();
              } else if (data.code === 422) {
                  ElMessage.error(data.data.msg);
              }
          });
        } else {
            return false;
        }
      });
    }

    return {
      ...toRefs(state),
      add,
      edit,
      resetForm,
      submitForm,
      form_invoice,
      cancel
    };
  },
  components: {
  },
};
</script>

<style lang="less" scoped>
.user_order {
  position: relative;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

  /deep/ .el-tabs__nav-wrap {
    .el-tabs__nav-scroll {
      height: 100%;
    }
    .el-tabs__active-bar {
      background-color: #1c46b7;
    }
    .el-tabs__item {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .el-tabs__item.is-active {
      color: #333333;
      font-size: 15px;
    }
    .el-tabs__item:hover {
      color: #999;
    }
    .el-tabs__item.is-active:hover {
      color: #333;
    }
  }

  .el-popover {
    padding: 0 !important;

    .popper__arrow {
      border-bottom-color: #f67322 !important;
      border-top-color: #f67322 !important;
    }
    .popper__arrow::after {
      border-bottom-color: #f67322 !important;
      border-top-color: #f67322 !important;
    }
  }
}

.edit_invoice{
    position: absolute;
    top: 30px;
    right: 50px;
    z-index: 998;
    padding: 0 9px;
    height: 27px;
    line-height: 27px;
    font-size: 12px;
    color: #999999;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    cursor: pointer;
}

.bgc_empty {
    padding: 30px 0 124px;
    text-align: center;
    .empty_wrap {
      padding: 104px 0 136px;
      width: 100%;
      background: rgba(245, 245, 245, 0.39);

    }

    img {
        width: 128px;
        height: 142px;
        margin-bottom: 12px;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #333333;
        margin-bottom: 12px;
    }
    .add {
      width: 121px;
      height: 32px;
      background: rgba(49, 109, 250, 1);
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      color: #FFFFFF;
      padding: 0;
      letter-spacing: 0;

    }
}

</style>


<style lang="less">

.smt_invoice {
  .warm_prompt {
    margin: 5px 0 20px;
    font-size: 12px;
    font-weight: 600;
    color: #ffb34b;
    line-height: 1;
    padding: 7px 9px;
    background: rgba(255, 247, 222, 0.39);
  }

  .el-form {
    padding-right: 560px;
  }


  .el-form-item {
      margin-bottom: 20px;

      .el-form-item__label {
        color: #666;
        font-size: 13px;
        text-align: right;
        padding-right: 30px;
      }

      .el-input {
        // width: 380px;

        .el-input__inner {
          height: 32px;
          background: #FFFFFF;
          border: 1px solid #D1D1D1;
          border-radius: 0!important;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          color: #333333;
          padding: 7px 16px;
        }
      }

      .el-input.is-disabled {
        .el-input__inner {
          border:1px solid #f5f5f5;
          color: #333;
          background: #f5f5f5;  
        } 
      }
  }

  .confirm_btns {
    padding: 18px 0;
    text-align: right;

    .el-button {
      width: 68px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      padding: 0;
      background: #FAFAFA;
    }
    .sure {
      background: #1C46B7;
      color: #fff;
      border: 1px solid #1C46B7;
    }
  }
}
</style>
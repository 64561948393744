<template>
    <div class="user_index">
        <div class="info d-between">
            <div>
                <div class="user_name">
                    <img class="avatar" :src="user.avatar" alt="">
                    <div>
                        <p class="name">{{user.nickname}}</p>
                        <p class="phone">{{user.phone}}</p>
                    </div>
                </div>

                <div class="user_vip d-center" v-if="user.vip == '会员'">
                    <img class="flag" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202109/24/icon/e-vipon-2.png"/>
                    <p class="money">会员有效期{{user.vip_endtime}}，<span class="go" @click="to_plus()">续费>></span></p>
                </div>
                <div class="user_vip d-center" v-else>
                    <img class="flag" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/17/%E9%A3%9E%E4%B9%A620211217-154837.png"/>
                    <p class="money">充值会员，来享受会员权益吧，<span class="go" @click="to_plus()">充值会员>></span></p>
                </div>

            </div>
            <ul class="list d-around">
                <li>
                    <div class="num">{{order_count}}</div>
                    <div class="sub">订单</div>
                </li>
                <li class="line"></li>
                <li>
                    <div class="num orange">{{coupon_count}}</div>
                    <div class="sub">优惠券</div>
                </li>
                <li class="line"></li>
                <li>
                    <div class="num orange">￥{{money_count}}</div>
                    <div class="sub">消费金额</div>
                </li>
                <li class="line"></li>
                <li>
                    <div class="num blue">{{user.ebc}}</div>
                    <div class="sub">E币</div>
                </li>
            </ul>
        </div>

        <div class="datum">
            <div class="user_title d-between">
                <span class="title">基本信息</span>
                <p class="edit d-center" @click="edit_info()"><i class="iconfont icon-e-bjicon"></i>编辑</p>
            </div>

            <el-form class="demo-ruleForm d-betweeen" :model="form" 
            :hide-required-asterisk="!change" :inline="true" :rules="rules" ref="form_user" label-width="128px">
                <el-form-item label="用户名" prop="nickname">
                    <p class="user_ctx" v-if="!change">
                        <span class="val" v-if="user.nickname">{{ user.nickname }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-input type="text" v-model="form.nickname" autocomplete="off" hide-required-asterisk placeholder="请输入用户名" maxlength="12" show-word-limit v-else>
                    </el-input>
                </el-form-item>

                <el-form-item label="工作行业">
                    <p class="user_ctx" v-if="!change">
                        <span class="val" v-if="user.industry">{{ user.industry }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-select v-else v-model="form.industry" placeholder="请选择工作行业">
                        <el-option label="计算机/互联网/通信/电子" value="计算机/互联网/通信/电子"></el-option>
                        <el-option label="金融/银行/保险" value="金融/银行/保险"></el-option>
                        <el-option label="贸易/消费/制造/营运" value="贸易/消费/制造/营运"></el-option>
                        <el-option label="制药/医疗" value="制药/医疗"></el-option>
                        <el-option label="物流/运输" value="物流/运输"></el-option>
                        <el-option label="能源/环保/化工" value="能源/环保/化工"></el-option>
                        <el-option label="政府/非营利组织/其他" value="政府/非营利组织/其他"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="邮箱">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.email">{{ user.email }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-input v-else type="text" v-model="form.email" autocomplete="off" hide-required-asterisk placeholder="请输入邮箱">
                    </el-input>
                </el-form-item>

                <el-form-item label="工作岗位">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.position">{{ user.position }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>

                    <el-select v-else v-model="form.position" placeholder="请选择工作岗位">
                        <el-option label="计算机/互联网/通信/电子" value="计算机/互联网/通信/电子"></el-option>
                        <el-option label="硬件研发工程师" value="硬件研发工程师" ></el-option>
                        <el-option label="软件开发工程师" value="软件开发工程师" ></el-option>
                        <el-option label="研发助理工程师" value="研发助理工程师" ></el-option>
                        <el-option label="嵌入式工程师" value="嵌入式工程师" ></el-option>
                        <el-option label="射频工程师" value="射频工程师" ></el-option>
                        <el-option label="电子工程师" value="电子工程师" ></el-option>
                        <el-option label="光学工程师" value="光学工程师" ></el-option>
                        <el-option label="维修工程师" value="维修工程师" ></el-option>
                        <el-option label="采购工程师" value="采购工程师" ></el-option>
                        <el-option label="测试工程师" value="测试工程师" ></el-option>
                        <el-option label="FPGA开发工程师" value="PGA开发工程师" ></el-option>
                        <el-option label="电气工程师" value="电气工程师" ></el-option>
                        <el-option label="机械工程师" value="机械工程师" ></el-option>
                        <el-option label="结构工程师" value="结构工程师" ></el-option>
                        <el-option label="算法工程师" value="算法工程师" ></el-option>
                        <el-option label="工艺工程师" value="工艺工程师" ></el-option>
                        <el-option label="通信工程师" value="通信工程师" ></el-option>
                        <el-option label="安防工程师" value="安防工程师" ></el-option>
                        <el-option label="PCB layout工程师" value="PCB layout工程师" ></el-option>
                        <el-option label="其他" value="其他" ></el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="绑定手机">
                    <span class="val" v-if="user.phone">{{ user.phone }}</span>
                    <span v-else class="grey">未填写</span>
                </el-form-item>
                <el-form-item label="工作单位">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.company">{{ user.company }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-input v-else type="text" v-model="form.company" autocomplete="off" hide-required-asterisk placeholder="请输入工作单位" >
                    </el-input>
                </el-form-item>

                <el-form-item label="绑定微信">
                    <span class="val" v-if="user.wechat_nickname">{{ user.wechat_nickname }}</span>
                    <span v-else class="grey">未绑定</span>
                </el-form-item>
                <el-form-item label="所在地区">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.area">{{ user.area }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-cascader
                        v-else
                        v-model="form.area"
                        :options="options"
                        :props="e_props"
                        @change="handleChange"
                        placeholder="请输入所在地区"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="绑定QQ">
                    <span class="val" v-if="user.qq_nickname">{{ user.qq_nickname }}</span>
                    <span v-else class="grey">未绑定</span>
                </el-form-item>
                <el-form-item label="入行时间">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.join_at">{{ user.join_at }}</span>
                    <span v-else class="grey">未填写</span>
                    </p>
                    <el-date-picker v-else v-model="form.join_at" type="date" placeholder="入行时间" value-format="YYYY-MM-DD"></el-date-picker>
                </el-form-item>
                
                <el-form-item label="个性签名" prop="sign" class="item_full">
                    <p class="user_ctx"  v-if="!change">
                        <span class="val" v-if="user.introduction">{{ user.introduction }}</span>
                        <span v-else class="grey">未填写</span>
                    </p>
                    <el-input
                        v-else
                        class="signature"
                        type="textarea"
                        v-model="form.introduction"
                        autocomplete="off"
                        hide-required-asterisk
                        maxlength="40" 
                        show-word-limit
                        placeholder="请输入个性签名"
                    >
                    </el-input>
                </el-form-item>

                <div class="dialog-footer" v-if="change">
                    <el-button class="cancle" @click="handleClose">取 消</el-button>
                    <el-button class="sure" @click="submit()">确 定</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import dataCity from "@/assets/city";
import { getData, postData } from "@/api/user";
import {toRefs, reactive, ref} from 'vue';
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default {
    
    setup () {
        const store = useStore();
        const form_user = ref(null)

        let isCellEmail = (val) => {
            if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val)) {
                return false;
            } else {
                return true;
            }
        }

        let checkemail = (rule, value, callback) => {
            if (value && ! isCellEmail(value)) {
                callback(new Error("请输入正确的邮箱"));
            } else {
                callback();
            }
        }

        let state = reactive({
            userid: store.state.userId,
            ali_oss: store.state.ali_oss,
            ebaina_charge_url: store.state.ebaina_charge_url,
            edit: false,
            order_count:0,
            coupon_count: 0,
            money_count: 0,
            change: false,
            user:{
                nickname:'',
                email:'',
                phone:'',
                wechat_nickname:'',
                qq_nickname:'',
                introduction:'',
                industry:'',
                position:'',
                company:'',
                area:'',
                join_at:''
            },
            form: {
                nickname:'',
                email:'',
                phone:'',
                wechat_nickname:'',
                qq_nickname:'',
                introduction:'',
                industry:'',
                position:'',
                company:'',
                area:[],
                area_copy:'',
                join_at:''
            },
            rules: {
                nickname: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
                email: [{ validator: checkemail, trigger: "blur" }],
            },
            options: dataCity,
            e_props: {
                expandTrigger: "hover",
                value: "value",
                label: "label",
                children: "children",
            },
        })

        let init = () => {
            getData({
                url: `api/my_home/my_index`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.user = data.data.user;
                    state.order_count = data.data.order_count
                    state.coupon_count = data.data.coupon_count
                    state.money_count = data.data.money_count
                    store.commit("setUserName", state.user.nickname);
                    store.commit("setAvatar", state.user.avatar);
                }
            })
        }
        init()

        let edit_info =  () => {
            state.change = true
            state.edit = true;
            state.form = JSON.parse(JSON.stringify(state.user))
            state.form.area_copy = state.user.area
            state.form.area = state.user.area ? state.user.area.split('/') : []
        }

        let handleChange = (value) => {
            state.form.area_copy = `${value[0]}/${value[1]}/${value[2]}`
        }

        let submit = () => {
            form_user.value.validate((valid) => {
                if (valid) {
                    postData({
                        url: `api/my_home/info/update `,
                        data: {
                            nickname:state.form.nickname,
                            email:state.form.email,
                            phone:state.form.phone,
                            introduction:state.form.introduction,
                            industry:state.form.industry,
                            position:state.form.position,
                            company:state.form.company,
                            area:state.form.area_copy,
                            join_at:state.form.join_at
                        },
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            state.change = false;
                            form_user.value.resetFields();
                            init ()
                        }else {
                            ElMessage.error(data.data.msg);
                        }
                    });
                }
            });
        }

        let handleClose = () => {
            state.change = false;
            init()
            form_user.value.resetFields();
        }

        let to_plus = () => {
            if(state.userid) {
                window.open(state.ebaina_charge_url,'_blank','')
            }else {
                store.commit("setLoginBox", true);
            }
        }
        

        return {
            ...toRefs(state),
            edit_info,
            form_user,
            submit,
            handleClose,
            handleChange,
            to_plus
        }

    }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
    width: 800px;
    
    .el-dialog__body {
        padding: 16px 50px;
    }

    .dialog-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #1C46B7;
    }


    
    .dialog-footer {
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #eaeaea;
            border-radius: 3px;
            font-size: 12px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #fff;
        }
        .sure {
            background: #1C46B7;
            color: #fff;
        }
    }
}

.info {
    height: 140px;
    width: 100%;
    background: #FFFFFF url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/03/%E9%A3%9E%E4%B9%A620211109-152652.png) no-repeat;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    margin-bottom: 25px;
    padding: 0 40px;



    .avatar {
        width: 58px;
        height: 58px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        margin-right: 20px;
    }
    .name {
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        color: #333;
        margin-bottom: 4px;
    }
    .phone {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #999;
    }

    .user_name {
        display: flex;
        align-items: center;
    }

    .user_vip {
        margin-top: 7px;
        .flag {
            height: 24px;
            width: 60px;
            margin-right: 18px;
        }
        .money {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #666;
            .go {
                color: #1C46B7;
                cursor: pointer;
            }
        }
    }
    .list {
        width: 50%;

        li {
            text-align: center;
        }

        .line {
            height: 24px;
            border-right: 1px solid #E1E1E1;
        }

        .num {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #333;
        }

        .num.orange {
            color: #FF7F23;
        }

        .num.blue {
            color: #1C46B7;
        }   

        .sub {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333;
            margin-top: 10px;
        }
    }
}

.datum {
    min-height: 540px;
    padding: 30px 40px;
    background: #FFFFFF; 
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    .user_title {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 64px;
        .title {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            border-bottom: 2px solid #1C46B7;
        }
        .edit {
            font-size: 12px;
            color: #1C46B7;
            cursor: pointer;
            .iconfont {
                font-size: 13px;
                margin-right: 5px;
            }
        }
    }

    .user {
        .user_item {
            display: inline-block;
            width: 49%;
            margin-bottom: 35px;
            font-size: 13px;
            color: #333333;
            line-height: 18px;
            .label {
                display: inline-block;
                font-size: 13px;
                color: #999;
                width: 80px;
                margin-right: 48px;
                vertical-align: middle;
            }
            .val {
                color: #333;
            }
            .grey {
                color: #b1b1b1;
            }
        }
        .user_full {
            width: 70%;
        }
        .user_ctx {
            display: inline-block;
        }
    }

}
</style>

<style lang="less">
/*.modal_edit {
    width: 422px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
    opacity: 1;
    border-radius: 8px;

    .el-dialog__header {
      padding: 18px 24px 24px;
      .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
      }
    }
    .el-dialog__body{
      padding: 0 24px;
    }
    .el-dialog__footer {
      padding: 0 24px 18px;

    }

    

    .err_title {
        font-size: 12px;
        line-height: 17px;
        color: #999;
        margin-bottom:28px;
    }
    
    .dialog_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .el-form-item {
      margin-bottom: 20px;

      .el-form-item__label {
        color: #666;
        font-size: 13px;
        text-align: left;
      }
      
      .el-input__inner {
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #D1D1D1;
        border-radius: none!important;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #333333;
        padding: 7px 16px;
      }
      .el-select {
        width: 100%;
      }
      .el-textarea__inner {
        width: 294px;
        height: 79px;
        background: #FFFFFF;
        border: 1px solid #D1D1D1;
        resize: none;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #333333;
        padding: 7px 16px;
      }
    }
    
}*/

.user_index {
    .el-form--inline .el-form-item {
        width: 48%;
        margin-bottom: 20px;
        font-size: 13px;
        color: #333333;
        line-height: 18px;
    }
    .el-form--inline .el-form-item.item_full {
        width: 80%;
    }
    .el-form-item__label {
        text-align: left;
        font-size: 13px;
        color: #999;
        width: 80px;
    }
    .el-form-item__content {
        padding-right: 70px;
    }
    .el-select,.el-cascader,.el-input {
        width: 100%!important;
    }
    .val {
        color: #333;
    }
    .grey {
        color: #b1b1b1;
    }
    .el-textarea__inner {
        padding: 8px 20px;
        width: 100%;
        height: 85px;
        background: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 2px;
        resize: none;
    }
    .dialog-footer {
        margin-top: 30px;
        padding-left: 30%;
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;
        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }

}
</style>

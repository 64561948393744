<!-- 收货地址 -->
<template>
  <div class="user_order">
    <div class="btn btn-primary addr_add" @click="changeAddress(true)">新增收货地址</div>
    <el-tabs v-model="active_name">
      <el-tab-pane label="收货地址" name="address">
          <div class="my_address_main">
            <div class="addr_wrap" v-if="addr_show">
                <ul class="addr-list">
                <li v-for="(v, idx) in addr_list" :key="idx">
                    <img
                    v-if="v.default == '是'"
                    class="normal-img"
                    src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211118-140054.png"
                    />

                    <p class="normal" @click="addr_default(v)" v-else>
                    设为默认地址
                    </p>

                    <div class="addr-info">
                    <div class="addr-info-item">
                        <span>收货人：</span>
                        <p>{{ v.name }}</p>
                    </div>
                    <div class="addr-info-item">
                        <span>所在地区：</span>
                        <p>{{ v.district }}</p>
                    </div>
                    <div class="addr-info-item">
                        <span>详细地址：</span>
                        <p>{{ v.address }}</p>
                    </div>
                    <div class="addr-info-item">
                        <span>手机/电话：</span>
                        <p>{{ v.tel }}</p>
                    </div>
                    <div class="addr-info-item" v-if="v.postcode">
                        <span>邮政编码：</span>
                        <p>{{ v.postcode }}</p>
                    </div>
                    </div>

                    <div class="addr-btns">
                    <button @click="changeAddress(true, 'edit', v)">修改</button>
                    <i></i>
                    <button @click="delAddr(v)">删除</button>
                    </div>
                </li>
                </ul>

                <div class="pagination">
                <el-pagination
                    v-if="total > page_size"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    layout="prev, pager, next, jumper"
                    :total="total"
                    :page-size="page_size"
                    prev-text="上一页"
                    next-text="下一页"
                    background
                >
                </el-pagination>
                </div>
            </div>

            <div class="bgc_empty" v-else>
                <img
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png"
                alt="百纳芯城"
                />
                <p>暂无数据</p>
            </div>
          </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 收货地址 -->
    <addaddress
        v-if="address_modal"
        :info="addressInfo"
        :type="addressType"
        @changeModal="changeAddress"
    ></addaddress>
  </div>
</template>

<script>
import addaddress from "@/components/AddAddress.vue";
import { getData, deleteData, putData } from "@/api/user";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "user_address",
  setup() {
    const store = useStore();
    const route = useRoute();

    let state = reactive({
        ali_oss: store.state.ali_oss,
        active_name: route.query.name || "address",

        // 地址
        address_modal: false, // 新增地址
        addr_show: true,
        addr_list: [], // 收货地址 收货地址
        addressInfo: null, // 收货地址
        addressType: "", // 收货地址
        
        // 分页
        page: 1, 
        total: 0,
        page_size: 0,
    });

    // 地址列表
    let get_address_list = () => {
      getData({
        url: "api/my_home/my_address",
        data: {},
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          let list = data.data.list;
          state.addr_list = list.data;
          state.addr_show = list.data.length > 0;
          state.total = list.total;
          state.page_size = list.per_page;
        } else {
          ElMessage.error(data.data.msg);
        }
      });
    };
    get_address_list();

    // 修改地址结束
    let changeAddress = (bol, type, info) => {
      state.address_modal = bol;

      if (type === "edit") {
        state.addressType = type;
        state.addressInfo = info;
      } else {
        state.addressType = "";
        state.addressInfo = {};
      }

      if (!bol) {
        get_address_list();
      }
    };

    // 删除地址
    let delAddr = (v) => {
      ElMessageBox.confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteData({
          url: "api/my_home/my_address/" + v.id,
          data: {},
        }).then((res) => {
          let data = res.data;
          if (data.code === 200) {
            ElMessage.success(data.data.msg);
            get_address_list();
          } else if (data.code === 422) {
            ElMessage.error(data.data.msg);
          } else {
            ElMessage.error(data.data.msg);
          }
        });
      });
    };

    // 设置默认地址
    let addr_default = (v) => {
      ElMessageBox.confirm("确认设置为默认地址？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        putData({
          url: "api/my_home/my_address/status/" + v.id,
          data: {},
        }).then((res) => {
          let data = res.data;
          if (data.code === 200) {
            ElMessage.success(data.data.msg);
            get_address_list();
          } else if (data.code === 422) {
            ElMessage.error(data.data.msg);
          } else {
            ElMessage.error(data.data.msg);
          }
        });
      });
    };

    // 分页变化
    let handleCurrentChange = (val) => {
      state.page = val;
      get_address_list();
    };

    return {
      ...toRefs(state),
      get_address_list,
      handleCurrentChange,
      changeAddress,
      delAddr,
      addr_default,
      open,
    };
  },
  components: {
    addaddress
  },
};
</script>

<style lang="less" scoped>

.user_order {
  position: relative;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

  /deep/ .el-tabs__nav-wrap {
    .el-tabs__nav-scroll {
      height: 100%;
    }
    .el-tabs__active-bar {
      background-color: #1c46b7;
    }
    .el-tabs__item {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .el-tabs__item.is-active {
      color: #333333;
      font-size: 15px;
    }
    .el-tabs__item:hover {
      color: #999;
    }
    .el-tabs__item.is-active:hover {
      color: #333;
    }
  }

  .el-popover {
    padding: 0 !important;

    .popper__arrow {
      border-bottom-color: #f67322 !important;
      border-top-color: #f67322 !important;
    }
    .popper__arrow::after {
      border-bottom-color: #f67322 !important;
      border-top-color: #f67322 !important;
    }
  }
}

.addr_add {
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 998;
  padding: 0 9px;
  height: 27px;
  line-height: 27px;
  font-size: 12px;
  color: #999999;
  text-align: center;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  cursor: pointer;
}
/*收货地址 */
.my_address_main {
  position: relative;

  .addr_wrap {
    padding-bottom: 50px;
  }
  .addr-list {
    li {
      position: relative;
      margin-bottom: 20px;
      padding: 24px 40px 20px 22px;
      background: #ffffff;
      border: 1px solid #eaeaea;

      .normal {
        display: none;
        position: absolute;
        top: 19px;
        right: 35px;
        font-size: 13px;
        line-height: 18px;
        color: #ff6c47;
        text-decoration: underline;
        cursor: pointer;
      }

      .normal-img {
        position: absolute;
        top: 0;
        right: 0;
        height: 87px;
        width: 85px;
      }

      .addr-info-item {
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        font-size: 13px;
        line-height: 18px;
        padding-right: 90px;

        span {
          width: 75px;
          text-align: right;
          color: #999;
        }

        p {
          color: #333;
        }
      }

      .addr-btns {
        position: absolute;
        bottom: 20px;
        right: 40px;
        display: flex;
        align-items: center;

        i {
          width: 1px;
          height: 14px;
          background: #707070;
          margin: 0 8px;
        }

        button {
          font-size: 13px;
          line-height: 18px;
          border: 0;
          outline: 0;
          color: #316dfa;
          background: transparent;
        }
      }
    }

    li:hover {
      .normal {
        display: block;
      }
    }
  }
}

.pagination {
  margin: 40px auto 0;
  text-align: center;
}

/deep/ .el-pagination {
  padding: 0;
  font-weight: 400;

  .el-pager {
    .number {
      height: 30px;
      line-height: 30px;
      color: #1c46b7;
      font-size: 16px;
      background: #ffffff;
      border: 1px solid #1c46b7;
      border-radius: 2px;
    }
    .number:hover {
      color: #1c46b7 !important;
    }

    .active:hover {
      color: #fff !important;
    }
  }

  .active {
    background-color: #1c46b7 !important;
  }

  button {
    height: 30px;
    line-height: 30px;
    background-color: #fff !important;
    border: 1px solid #666;

    span {
      padding: 0 12px;
      font-size: 16px;
      color: #666;
    }
  }

  .el-pagination__jump {
    margin-left: 0;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #666;

    .el-input__inner {
      color: #666;
      border: 1px solid #666;
      border-radius: 2px;
    }
  }
}

.bgc_empty {
  padding-top: 100px;
  text-align: center;

  img {
    width: 128px;
    height: 142px;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
  }
}
</style>


<!-- 我的订单 -->
<template>
    <div class="user_msg">
        <el-tabs v-model="active_name">
            <el-tab-pane label="我的消息" name="msg">
                <div class="msg_wrap">
                    <div v-if="msg_show">
                        <ul class="msg_list" >
                            <li class="msg_item" v-for="(item, idx) in msg_list" :key="idx">
                                <div class="msg_item_wrap">
                                  <div class="title d-between">
                                      <p class="d-center"><span v-if="!item.read_at" class="circle"></span>{{item.data && item.data.content ? item.data.content : ''}}</p>
                                      <span class="del" @click="clear(item)">删除</span>
                                  </div>
                                  <div class="time">{{item.create_time}}</div>
                                </div>
                            </li>
                        </ul>
                        <div class="product_page d-center">
                            <el-pagination
                                v-if="total > page_size"
                                @current-change="handleCurrentChange"
                                :current-page="page"
                                layout="total, prev, pager, next, jumper"
                                :total="total"
                                :page-size="page_size"
                                background>
                            </el-pagination>
                        </div>
                    </div>
                    <div class="bgc_empty" v-else>
                        <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="百纳芯城"/>
                        <p>暂无数据</p>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="clear" @click="clear_all()">全部已读</div>
    </div>
</template>

<script>
import { getData,deleteData } from "@/api/user";
import { toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default {
    name: "user_msg",
    setup() {
        const store = useStore();

        let state = reactive({
            ali_oss: store.state.ali_oss,
            active_name: "msg",
            msg_list: [],
            msg_show: true,
            page: 1,
            total: 0,
            page_size: 0
        });

        let get_msg_list = () => {
            getData({
                url: `api/my_home/notifications?page=${state.page}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) { 
                    state.total =data.data.total
                    state.page_size = data.data.per_page
                    state.msg_list = data.data.data;
                    state.msg_show = state.msg_list.length> 0
                } else {
                    ElMessage.error(data.data.msg);
                }
            });
        };
        get_msg_list();

        // 清空单个
        let clear = (v) => {
            deleteData({
                url: `api/my_home/notifications/${v.id}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    ElMessage.success(data.data.msg);
                    get_msg_list()
                } else {
                    ElMessage.error(data.data.msg);
                }
            });
        };

        // 清空所有
        let clear_all = () => {
            getData({
                url: "api/my_home/notifications/clear",
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    // ElMessage.success(data.data.msg);
                    get_msg_list()
                } else if (data.code === 422) {
                    ElMessage.error(data.data.msg);
                }else {
                    ElMessage.error(data.data.msg);
                }
            });
        };

        let handleCurrentChange = (val) => {
            state.page = val
            get_msg_list();
        }

        return {
            ...toRefs(state),
            get_msg_list,
            clear,
            clear_all,
            handleCurrentChange
        };
    },
};
</script>

<style lang="less" scoped>
.pagination {
    margin: 40px auto 0;
    text-align: center;
    }

    /deep/ .el-pagination {
    padding: 0;
    font-weight: 400;

    .el-pager {
        .number {
        height: 30px;
        line-height: 30px;
        color: #1C46B7;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #1C46B7;
        border-radius: 2px;
        }
        .number:hover {
        color: #1C46B7 !important;
        }

        .active:hover {
        color: #fff !important;
        }
    }

    .active {
        background-color: #1C46B7 !important;
    }

    button {
        height: 30px;
        line-height: 30px;
        background-color: #fff !important;
        border: 1px solid #666;

        span {
        padding: 0 12px;
        font-size: 16px;
        color: #666;
        }
    }

    .el-pagination__jump {
        margin-left: 0;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: #666;

        .el-input__inner {
        color: #666;
        border: 1px solid #666;
        border-radius: 2px;
        }
    }
}
.user_msg {
    position: relative;
    padding: 30px 40px;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

    /deep/ .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
        height: 100%;
        }
        .el-tabs__active-bar {
        background-color: #1c46b7;
        }
        .el-tabs__item {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
        }
        .el-tabs__item.is-active {
            color: #333333;
            font-size: 15px;
        }
        .el-tabs__item:hover {
            color: #999;
        }
        .el-tabs__item.is-active:hover {
            color: #333;
        }
    }
    .clear {
        position: absolute;
        top: 30px;
        right: 40px;
        z-index: 99;
        padding: 0 9px;
        height: 27px;
        line-height: 27px;
        font-size: 12px;
        color: #013EAA;
        text-align: center;
        background: #ffffff;
        border: 1px solid #013EAA;
        border-radius: 3px;
        cursor: pointer;
    }
}

.msg_wrap {
    position: relative;
    padding: 12px 0;
    .msg_list {
        .msg_item {
            background: #fff; 
            
            padding: 16px 24px 0 18px;
            .msg_item_wrap {
              border-bottom: 1px solid #E9E9E9;
              padding:0 0 18px 0;
            }
            .circle {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: #E81919;
                border-radius: 50%;
                margin-right: 5px;
                /*position: absolute;
                top: 50%;
                left: -9px;
                transform: translateY(-50%);*/
            }
            .title {
                position: relative;
                font-size: 13px;
                line-height: 18px;
                color: #333;
                margin-bottom: 12px;
            }
            .time {
                font-size: 12px;
                line-height: 17px;
                color: #999; 
            }
            .del {
                font-size: 12px;
                line-height: 17px;
                color: #559BFF;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .msg_item:hover  {
            background: #F5F9FF; 
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);

            .msg_item_wrap {
              border-bottom: 1px solid #F5F9FF;
            }
        }
    }
}

.bgc_empty {
    padding-top: 100px;
    text-align: center;

    img {
        width: 128px;
        height: 142px;
        margin-bottom: 20px;
    }

    p {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #333333;
    }
}

.product_page {
    padding: 40px 0 0;
}
</style>

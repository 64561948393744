<template>
    <div class="user_order">
        <el-tabs v-model="active_name">
            <el-tab-pane label="商品订单" name="order">
                <div class="my_orders_main">
                    <div class="order_list_ul" v-if="order_show">
                        <div class="order_list_li" v-for="(item, idx) in order_list" :key="idx">
                            <div class="li_title d-between">
                                <div class="order_count">
                                订单号：<span>{{ item.serial_number }} </span>
                                </div>
                                <div class="time"><span :class="item.logistics_status == '待发货' ?'over': ''">{{item.logistics_status}}</span> {{ item.created_at }} </div>
                            </div>

                            <div class="li_bottom_main">
                                <div class="li_list" v-for="(v, k) in item.order_details" :key="k">
                                    <div class="info_order d-between" :class="{border_btm_none: k + 1 == item.order_details.length}">
                                        <div class="info_item" @click="to_detail(v)">
                                            <img class="item_img" :src="v.goods && v.goods.thumbs && v.goods.thumbs[0] ? v.goods.thumbs[0]:''" />
                                            <div class="right_item">
                                                <div class="item_name">{{ v.goods.name }} <span class="type" v-if="v.goods.second_category">{{v.goods && v.goods.second_category.name ? v.goods.second_category.name : ''}}</span></div>
                                                <div class="item_type"><b>规格型号：</b><span>{{v.goods.standard}}</span></div>
                                                <div class="item_type"><b>封装：</b><span>{{v.goods.package}}</span> </div>
                                                <div class="item_money_count">¥{{ v.price }}</div>
                                            </div>
                                        </div>
                                        <div class="info_text">
                                            <p class="txt_num">￥{{v.money}}</p>
                                            <p class="txt_num">x{{v.num}}</p>
                                            <div class="order_box pay_box">
                                                <span class="box_title box_title_money">实付</span> 
                                                <span class="box_val box_shi">￥{{v.money_real}}</span>
                                            </div>
                                            
                                            
                                            <div class="d-between">
                                                <p class="txt_buy" @click="to_detail(v)">再次购买</p>
                                                <p class="box_title box_refund" @click="refund(v,true)" v-if="v.status == -1">退款</p> 
                                                <p class="box_title box_status" v-if="v.status == 1">退款中</p>
                                                <p class="box_title box_status" v-if="v.status == 2">已退款</p>
                                                <p class="box_title box_status" v-if="v.status == 3">拒绝退款</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info_bottom">
                                    <div class="info_btn">
                                        <div class="address" v-if="item.address">
                                            <p class="name"><span>收货人：</span>{{item.address.name}} {{item.address.tel}}</p>
                                            <p class="name"><span>收货地址：</span>{{item.address.district}} {{item.address.address}}</p>
                                        </div>
                                        <div class="btns">
                                            <div class="other" @click="to_smt()">申请SMT打样</div>
                                            <div class="other" v-if="item.logistics_status == '待发货' || item.logistics_status == '已发货' || item.logistics_status == '已收货'"  @click="refund_any(item)">批量退款</div>
                                            <div class="other" v-if="item.invoice_status == '未申请'"  @click="open(item)">申请开票</div>
                                            <div class="sure"  v-if="item.logistics_status == '已发货'" @click="take(item)">确认收货</div>
                                            <div class="pact"  @click="pact(item)">订单合同</div>
                                        </div>
                                    </div>    
                                    <div class="info_sale">
                                        <div class="order_box" v-if="item.ebc_deduction"><span class="box_title">E币抵扣</span> <span class="box_val">-￥{{item.ebc_deduction}}</span></div>
                                        <div class="order_box" v-if="item.coupon && item.coupon.dec_money"><span class="box_title">优惠券</span> <span class="box_val">-￥{{item.coupon && item.coupon.dec_money ? item.coupon.dec_money : ''}}</span></div>
                                        <div class="order_box" v-if="item.freight"><span class="box_title">邮费</span> <span class="box_val">￥{{item.freight}}</span></div>
                                        <div class="order_box order_money">

                                        <el-popover
                                            v-if="item.logistics_status == '已发货' || item.logistics_status == '已收货'"
                                            popper-class="log_pop"
                                            placement="bottom-start"
                                            :width="200"
                                            trigger="hover"
                                            >
                                                <template v-slot:reference> 
                                                    <span class="log" @mouseenter.stop="order_info(item)">查看物流</span>
                                                </template> 
                                            <div class="logi_box">
                                                <div class="log_title">{{ express_company }} ：{{ express_number }} </div>
                                                <ul class="log_list">
                                                    <li v-for="(v, k) in logistics" :key="k">
                                                        <span class="dian"></span>
                                                        <span class="ctx">{{v.context}}</span>
                                                    </li>
                                                </ul> 
                                            </div>
                                        </el-popover>
                                            

                                            <span class="box_title box_title_money">总实付金额</span> 
                                            <span class="box_val money_val">￥{{ item.total_money_real }}</span>
                                        </div>
                                        <div class="ele" v-if="item.invoice_status == '已申请'">已申请{{item.invoice_type}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bgc_empty" v-else>
                        <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="百纳芯城"/>
                        <p>暂无数据</p>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <!-- 申请开票 -->
        <el-dialog
            custom-class="modal_open"
            v-model="open_show"
            :before-close="cancle"
        >
            <template v-slot:title>
                <div class="dialog-title">申请开票</div>
            </template>  
            <div class="dialog-content">
                <div class="open_check d-flex">
                    <span class="open_type">类型：</span>
                    <el-radio-group class="d-flex" v-model="openCheck" @change="change_type">
                        <el-radio :label="'电子发票'">电子发票</el-radio>
                        <el-radio :label="'增值税普票'">增值税普票</el-radio>
                        <el-radio :label="'增值税专票'">增值税专票</el-radio>
                    </el-radio-group>
                </div>

                <ul class="open_list">
                    <li class="open_box"><span class="open_title">公司名称:</span> <span class="open_val">{{openInfo.company || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">公司税号:</span> <span class="open_val">{{openInfo.tax_number || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">单位地址:</span> <span class="open_val">{{openInfo.address || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">电话号码:</span> <span class="open_val">{{openInfo.tel || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">开户银行:</span> <span class="open_val">{{openInfo.deposit_bank || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">银行账号:</span> <span class="open_val">{{openInfo.bank_account || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">电子邮箱:</span> <span class="open_val">{{openInfo.email || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">收 票 人:</span> <span class="open_val">{{openInfo.get_name || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">联系电话:</span> <span class="open_val">{{openInfo.get_tel || '/'}}</span> </li>
                    <li class="open_box"><span class="open_title">收票地址:</span> <span class="open_val">{{openInfo.get_address || '/'}}</span> </li>
                </ul>
                <p class="open_tip">*纸质发票需自付邮费，平台将会采用邮费到付的形式</p>

            </div>
            <template v-slot:footer>
                <div class="dialog-footer">
                    <el-button class="cancle" @click="cancle()">取 消</el-button>
                    <el-button class="cancle" @click="edit()">编 辑</el-button>
                    <el-button class="sure" @click="submit()">确 定</el-button>
                </div>
            </template>  
        </el-dialog>

        <!-- 批量退款 -->
        <el-dialog custom-class="modal_any" v-model="any_show" :before-close="cancle_any">
            <template v-slot:title>
                <div class="dialog-title">批量退款</div>
            </template>  
            <div class="dialog-content">
                <div class="any_table">
                    <div class="any_title">
                        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" v-if="check">全选</el-checkbox>
                        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" disabled v-else>全选</el-checkbox>
                    </div>
                    <div class="any_body">
                        <el-checkbox-group v-model="checkedIds" @change="handleCheckedCitiesChange">
                        <div class="li_list" v-for="(v, k) in any_detail" :key="k">
                            <div class="info_order d-between" >
                                <div class="info_item" >
                                    <el-checkbox :label="v.id" v-if="v.status == -1">{{''}}</el-checkbox>
                                    <el-checkbox :label="v.id" v-else disabled>{{''}}</el-checkbox>
                                    <img class="item_img" :src="v.goods && v.goods.thumbs && v.goods.thumbs[0] ? v.goods.thumbs[0]:''" @click="to_detail(v)"/>
                                    <div class="right_item" @click="to_detail(v)">
                                        <div class="item_name">{{ v.goods.name }} <span class="type" v-if="v.goods.second_category">{{v.goods && v.goods.second_category.name ? v.goods.second_category.name : ''}}</span></div>
                                        <div class="item_type"><b>规格型号：</b><span>{{v.goods.standard}}</span></div>
                                        <div class="item_type"><b>封装：</b><span>{{v.goods.package}}</span> </div>
                                        <div class="item_money_count">¥{{ v.price }}</div>
                                    </div>
                                </div>
                                <div class="info_text">
                                    <p class="txt_num">￥{{v.money}}</p>
                                    <p class="txt_num">x{{v.num}}</p>
                                    <div class="order_box pay_box">
                                        <span class="box_title box_title_money">实付</span> 
                                        <span class="box_val box_shi">￥{{v.money_real}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </el-checkbox-group>
                    </div>
                
                
                </div>
                <div class="any_reason">退款原因</div>
                <div class="open_check">
                    <el-radio-group class="" v-model="refund_type" @change="change_refund">
                        <el-radio :label="'商品质量问题'">商品质量问题</el-radio>
                        <el-radio :label="'拍错了'">拍错了</el-radio>
                        <el-radio :label="'实际与描述不符'">实际与描述不符</el-radio>
                        <el-radio :label="'卖家发错货了'">卖家发错货了</el-radio>
                        <el-radio :label="'其他'">其他</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <template v-slot:footer>
                <div class="dialog-footer">
                    <el-button class="cancle" @click="cancle_any()">取 消</el-button>
                    <el-button class="sure" @click="submit_any()">提 交</el-button>
                </div>
            </template>  
        </el-dialog>

        <!-- 退款原因 -->
        <el-dialog custom-class="modal_refund" v-model="refund_show" :before-close="cancle_refund">
            <template v-slot:title>
                <div class="dialog-title">退款原因</div>
            </template>  
            <div class="dialog-content">
                <div class="open_check">
                    <el-radio-group class="" v-model="refund_type" @change="change_refund">
                        <el-radio :label="'商品质量问题'">商品质量问题</el-radio>
                        <el-radio :label="'拍错了'">拍错了</el-radio>
                        <el-radio :label="'实际与描述不符'">实际与描述不符</el-radio>
                        <el-radio :label="'卖家发错货了'">卖家发错货了</el-radio>
                        <el-radio :label="'其他'">其他</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <template v-slot:footer>
                <div class="dialog-footer">
                    <el-button class="cancle" @click="cancle_refund()">取 消</el-button>
                    <el-button class="sure" @click="submit_refund()">提 交</el-button>
                </div>
            </template>  
        </el-dialog>

        <!--开票信息-->
        <addbill
            v-if="open_modal"
            :info="openInfo"
            :type="openType"
            @changeModal="changeBill"
        ></addbill>

        <!-- 合同  -->
        <div style="position:fixed;top:-99999999999px;left:0;">
          <div id="pdfCentent" class="pdfCentent">
            <h1>百纳芯城订货合同</h1>
            <div class="pdf_box d-between">
              <p>合同编号：{{info.contract_number}}</p>
              <p>签订日期：{{info.contract_time}}</p>
            </div>
            <div class="pdf_box">
              <p>甲方：{{info.contract_name}}</p>
              <p>乙方：南京启诺信息技术有限公司</p>
              <p>单位地址：南京市江宁区菲尼克斯路70号总部基地22栋一楼</p>
              <p>联系方式：18651648139</p>
              <p>开户行：建设银行南京江宁经济开发区支行</p>
              <p>账户：32050159605600000324</p>
              <p>签字盖章：</p>
              <img class="zhang" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/%E9%A3%9E%E4%B9%A620220104-101619.png" alt="">
            </div>
            <div class="pdf_box">
              <p>一、甲乙双方在平等自愿、协商一致的基础上达成货物买卖合同条款，取代合同签署前双方间书面或者口头的交流、谈判和协议，双方遵照履行。</p>
              <p>二、甲方按下表所列的价款向乙方支付货款，以下价格含13%增值税：</p>
              <table class="pdf_table">
                <tr class="bg"> 
                  <td>序号</td>
                  <td>商品名称</td>
                  <td>规格型号/封装</td>
                  <td>数量</td>
                  <td>单价（元）</td>
                  <td>合计（元）</td>
                </tr>
                <tr v-for="(v,k) in info.order_details" :key="k"> 
                  <td>{{k+1}}</td>
                  <td>{{v.goods.name }}</td>
                  <td>{{v.goods.standard}}</td>
                  <td>{{v.num}}</td>
                  <td>￥{{v.money}}</td>
                  <td>￥{{v.money_real}}</td>
                </tr>
                <tr>
                  <td colspan="2">运费：￥{{info.freight}}</td>
                  <td colspan="2">优惠折扣： <span v-if="info.deduction_money > 0">-￥{{info.deduction_money}}</span><span v-else>-￥0</span></td>
                  <td colspan="2">实付总额：￥{{ info.total_money_real }}（含增值税）</td>
                </tr>
              </table>
              <p>1.乙方不提供无条件退换货服务。</p>
              <p>2.乙方所售商品均为原厂或代理商正规渠道进货，保证原装正品。</p>
              <p>3.乙方代为订货和代购的产品甲方不得取消，若乙方因市场环境。原厂，供应商等原因无法交货的乙方可联系甲方协商取消订单，乙方不承担额外赔偿。</p>
              <p>4.乙方代为订货或代购的产品，如因原厂或者代理商原因价格出现上涨，乙方客服人员将第一时间通知您，并按照乙方采购成本价补付差额。</p>
              <p>5.甲方自行在乙方商城上下单采购商品，乙方承诺发货商品与甲方实际购买产品一致。甲方在收到商品后应及时验收，对商品数量、型号、质量有异议的，应在收货之日起30天以书面形式提出，逾期想不受理。乙方不承相除所售商品之外的任何经济赔偿。</p>
              <p>6.本订单的付款方式为网络支付，然后发货(特殊情况除外)。</p>
              <p>7.发放本协议为电子台同，乙方系统自动生成。</p>
            </div>
          </div>
          <el-button ref="pdf" type="danger" @click="ExportSavePdf()">导出PDF</el-button>
        </div>
    </div>
</template>

<script>
import addbill from '@/components/AddBill.vue'
import { getData,postData } from "@/api/user";
import { toRefs, reactive,ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import { setTimeout } from 'timers';

export default {
    name: "user_order",
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter()
        const pdf = ref(null)

        let state = reactive({
            ali_oss: store.state.ali_oss,
            active_name: route.query.name || "order", 

            // 订单
            order_list: [], // 订单 记录
            order_show: true,
            order_id: 0,

            // 开票
            open_show: false,
            open_modal: false,
            openInfo: null, 
            openCheck: '电子发票',
            openType: "",

            // 退款
            refund_url:'',
            refund_type: '商品质量问题',
            refund_show: false,
            any_info: {},
            any_show: false,
            any_detail:[],
            check: false,
            checkAll: false,
            checkedIds: [],
            
            // 物流
            log_show: false,
            logistics: [], // 物流订单 详情信息
            express_company: "物流公司",
            express_number: "123234234",

            // pdf
            info:{}

        });

        // 订单列表
        let get_order_list = () => {
            getData({
                url: `api/my_home/my_order`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.order_list = data.data.order
                    state.order_show = data.data.order && data.data.order.length > 0
                }else {
                    ElMessage.error(data.data.msg);
                }
            });
        };
        get_order_list();


        // 物流信息
        let order_info = (v) => {
            state.logistics = [];

            getData({
                url: `api/my_home/logistics/${v.id}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let info = data.data.info;
                    if (info.code == 200) {
                        state.express_company = info.express.company;
                        state.express_number = info.express.number;
                        state.logistics = info.data;
                    }
                } else if (data.code === 422) {
                    ElMessage.error(data.data.msg);
                } else {
                    ElMessage.error(data.data.msg);
                }
            });
        };


        // 开票
        let open = (v) => {
            state.order_id = v.id
            getData({
                url: "api/invoices",
                data: {
                    order_id: v.id
                },
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.openInfo = data.data.info;
                }else {
                    state.openInfo = null
                    ElMessage.error(data.data.msg);
                }
                if(state.openInfo != null) {
                    state.open_show = true
                }else {
                    changeBill(true)
                }
            })
        }

        // 开票弹框
        let changeBill = (v, type, check) => {
            state.open_modal = v;
            state.openType = type;
            if (!v && check) {
                if(type == 'edit') {
                    state.open_show = true
                }else {
                    state.openCheck = check
                    submit()
                }
            }
        };

        // 开票类型
        let change_type = (v) => {
            state.openCheck = v
        }

        let submit = () => {
            postData({
                url: `api/my_home/inovice_order/${state.order_id}`,
                data: {
                    invoice_id: state.openInfo.id,
                    invoice_type:state.openCheck
                },
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    ElMessage.success(data.data.msg);
                    get_order_list()
                    state.open_modal = false
                    state.open_show = false
                }else {
                    ElMessage.error(data.data.msg);
                }
            })
        }

        let cancle = () => {
            state.open_show = false
        }

        let edit = () => {
            state.open_show = false
            changeBill(true,'edit')
        }

        // 退款
        let refund = (v,bol) => {
            state.refund_url = bol ? `api/my_home/refund_order_detail/${v.id}`: `api/my_home/refund_order/${v.id}`
            state.refund_show = true
        }

        let change_refund = (v) => {
            state.order_id = v.id
            state.refund_type = v
        }

        let cancle_refund = () => {
            state.refund_show = false
            state.refund_type = '商品质量问题'
        }

        let submit_refund = () => {
            postData({
                url: state.refund_url,
                data: {
                    refund_reason:state.refund_type
                },
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    ElMessage.success(data.data.msg)
                    state.refund_show = false
                    state.refund_type = '商品质量问题'
                    get_order_list()
                }else {
                    ElMessage.error(data.data.msg)
                }
            });
        }

        let refund_any = (v) => {
            state.order_id = v.id
            getData({
                url: `api/my_home/batch_refund/${v.id}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.any_info = data.data.info
                    state.any_detail = data.data.info.order_details
                    state.any_show = true

                    if(state.any_detail.length > 0 ) {
                        let check = false
                        state.any_detail.forEach((v)=>{
                            if(v.status == -1) {
                                check= true;
                            }
                        })
                        state.check = check
                    }
                } else {
                    ElMessage.error(data.data.msg);
                }
            });

            
        }

        let cancle_any = () => {
            state.any_show = false
            state.checkedIds = []
            state.checkAll = false
            state.refund_type = '商品质量问题'
        }

        let submit_any = () => {
            if(state.checkedIds.length > 0) {
                postData({
                    url: `api/my_home/batch_refund/${state.order_id}`,
                    data: {
                        refund_reason:state.refund_type,
                        ids: state.checkedIds
                    },
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        ElMessage.success(data.data.msg)
                        state.any_show = false
                        state.checkedIds = []
                        state.checkAll = false
                        state.refund_type = '商品质量问题'
                        get_order_list()
                    }else {
                        ElMessage.error(data.data.msg)
                    }
                });
            }else {
                ElMessage.error('请至少选择一项')
            }

        }

        const handleCheckAllChange = (val) => {
            let arr = []
            if(state.any_detail.length > 0) {
                state.any_detail.forEach((v)=>{
                    if(v.status == -1) {
                        arr.push(v.id)
                    }
                    
                })
            }
            state.checkedIds = val ? arr : []
        }
        const handleCheckedCitiesChange = (value) => { 
            state.checkAll = value.length === state.any_detail.length
        }

        // 确认收货
        let take = (v) => {
            postData({
                url: `api/my_home/confirm_order/${v.id}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    ElMessage.success(data.data.msg);
                    get_order_list()
                }else {
                    ElMessage.error(data.data.msg);
                }
            })
        }

        let pact = (v) => {
          state.info = v
          setTimeout(()=>{
            pdf.value.$emit('click')
          },500)
        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query: {
                    id: v.goods_id
                }
            })
            window.scrollTo(100,0)
        }

        let to_smt = () => {
            router.push({path: '/smt'})
        }

        return {
            pdf,
            ...toRefs(state),
            get_order_list,
            order_info,
            to_detail,
            submit,
            pact,
            take,
            cancle,
            edit,
            open,
            change_type,
            to_smt,
            changeBill,
            refund,
            change_refund,
            submit_refund,
            cancle_refund,
            refund_any,
            cancle_any,
            submit_any,
            handleCheckAllChange,
            handleCheckedCitiesChange,
        };
    },
    components: {
        addbill
    },
};
</script>

<style lang="less" scoped>

.user_order {
    position: relative;
    padding: 30px 40px;
    background: #FFFFFF; 
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

    /deep/ .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
            height: 100%;
        }
        .el-tabs__active-bar {
            background-color: #1C46B7;
        }
        .el-tabs__item {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
        }
        .el-tabs__item.is-active {
            color: #333333;
            font-size: 15px;
        }
        .el-tabs__item:hover {
            color: #999;
        }
        .el-tabs__item.is-active:hover {
            color: #333;
        }
    }

    .el-popover {
        padding: 0 !important;

        .popper__arrow {
            border-bottom-color: #f67322 !important;
            border-top-color: #f67322 !important;
        }
        .popper__arrow::after {
            border-bottom-color: #f67322 !important;
            border-top-color: #f67322 !important;
        }
    }
}

/* 商品订单 */
.my_orders_main {
    position: relative;

    .order_list_ul {
        .order_list_li {
            margin-bottom: 30px;

            .li_title {
                padding: 14px 44px 14px 14px;
                font-size: 12px;
                color: #666666;
                border: 1px solid #EAEAEA;
                border-bottom: none;

                .order_count {
                    span {
                        font-size: 13px;
                        color: #333333;
                    }
                }

                .time {
                    span {
                        color:#999;
                        margin-right: 30px;
                    }
                    span.over {
                        color:#1C46B7;

                    }

                }
            }

            .li_bottom_main {
                border: 1px solid #eaeaea;
            }

            .li_list {
                .info_order {
                    padding: 8px 44px 14px 14px;
                    width: 100%;
                    border-bottom: none;

                    .info_item {
                        cursor: pointer;
                        display: flex;
                        width: 100%;
                        .item_img {
                            margin-right: 24px;
                            width: 90px;
                            height: 90px;
                            flex-shrink: 0;
                        }

                        .right_item {
                            width: 100%;
                            font-size: 12px;
                            color: #333333;
                            line-height: 1;

                            .item_name {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                line-height: 22px;
                                font-size: 12px;
                                font-weight: 600;

                                span {
                                    font-size: 10px;
                                    color: #FFFFFF;
                                    background: #559BFF;
                                    opacity: 1;
                                    border-radius: 2px;
                                    padding: 1px 6px;
                                    margin-left: 16px;
                                }
                            }

                            .item_type {
                                margin: 10px 0 10px;
                                color: #666;
                                b {
                                    display: inline-block;
                                    font-weight: normal;
                                    //width: 60px;
                                }
                                span {
                                    color: #999;
                                    margin-left: 6px;
                                }
                            }

                            .item_money_count {
                                color: #1C46B7;
                            }
                        }
                    }

                    .info_text {
                        text-align: right;
                        font-size: 12px;
                        color: #666;
                        line-height: 17px;
                    }
                    .txt_num {
                        margin-bottom: 8px;
                        color: #999;
                    }
                    .txt_buy {
                        font-size: 12px;
                        line-height: 17px;
                        color: #1C46B7;
                        text-decoration: underline;
                        width: 80px;
                        cursor: pointer;
                    }
                }

                .border_btm_none {
                    border-bottom: 1px solid #eaeaea !important;
                    margin-bottom: 12px;
                }



                .info_logistics {
                    padding: 19px 15px;
                    width: 140px;
                    text-align: center;
                    flex-shrink: 0;

                    .no_shipments {
                        font-size: 12px;
                        color: #999999;
                    }

                    .check_the_logistics {
                        position: relative;

                        .txt {
                            cursor: pointer;
                            font-size: 12px;
                            color: #c90416;
                            text-decoration: underline;
                        }
                    }
                }

                .logistics_box {
                    // position: absolute;
                    // top: 0;
                    // left: 50%;
                    // transform: translateX(-50%);
                    padding: 0 16px 10px;
                    width: 267px;
                    height: 333px;
                    background: #ffffff;
                    border: 1px solid #f67322;
                    border-radius: 1px;
                    z-index: 2;

                    .log_container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        .title {
                        margin-bottom: 10px;
                        padding: 17px 0 12px;
                        font-size: 12px;
                        font-weight: 700;
                        color: #666666;
                        text-align: left;
                        border-bottom: 1px solid #eaeaea;
                        }

                        .log_info_ul {
                        margin-right: -13px;
                        padding-bottom: 42px;
                        height: 100%;

                        overflow: auto;

                        .log_info_li {
                            display: flex;
                            margin: 0 0 15px;
                            text-align: left;

                            .left_dot {
                            margin-top: 4px;
                            margin-right: 10px;
                            width: 6px;
                            height: 6px;
                            background: #050505;
                            border-radius: 50%;
                            flex-shrink: 0;
                            }

                            .right_txt {
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 17px;
                            color: #333333;
                            }
                        }

                        .active {
                            .left_dot {
                            background: #ff6c47;
                            }

                            .right_txt {
                            color: #ff6c47;
                            }
                        }
                        }
                    }
                }
            }

            .info_bottom {
                padding: 0 44px 14px 14px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .info_btn {
                    .address {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #333333;
                        margin-bottom: 45px;
                        p {
                            margin-bottom: 6px;
                        }
                        span {
                            width: 70px;
                            color: #999999;
                        }
                    }

                    .btns {
                        display: flex;
                        align-items: center;
                    }
                    
                    .other,.sure {
                        font-size: 12px;
                        line-height: 17px;
                        color: #666666;
                        padding: 6px 12px;
                        border: 1px solid #8E8E8E;
                        border-radius: 2px;
                        margin-right: 12px;
                        cursor: pointer;
                    }
                    .sure {
                        background: #1C46B7;
                        color: #fff;
                        border: 1px solid #1C46B7;
                    }
                    .pact {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #666666;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                .info_sale {
                    text-align: right;
                    font-size: 12px;
                    color: #666;

                }
            }

            .order_box {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 8px;
            }

            .pay_box {
                color: #333;
                //font-weight: bold;
            }

            .box_title {
                min-width: 50px;
                color: #666;
            }

            .box_title_money {
                color: #333;
            }

            .box_refund {
                text-decoration: underline;
                cursor: pointer;
            }
            .box_status {
                color: #999;
            }

            .box_val {
                min-width: 90px;
                color: #999;
            }

            .box_shi {
                color: #333;
            }
            
            .log {
                font-size: 12px;
                line-height: 17px;
                color: #FF7F23;
                margin-right: 30px;
                text-decoration: underline;
                cursor:pointer;
            }

            .money_val {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                color: #FF7F23;
            }
            
            .ele {
              color: #999;
            }

        }
    }
}

.bgc_empty {
    padding-top: 100px;
    text-align: center;

    img {
        width: 128px;
        height: 142px;
        margin-bottom: 20px;
    }

    p {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #333333;
    }
}

.open_check {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.open_type {
    font-size: 13px;
    line-height: 18px;
    color: #666;
    width: 65px;
}

.open_list {
    .open_box {
        display: flex;
        margin-bottom: 16px;
        font-size: 13px;
        line-height: 18px;
        color: #666666;
    }
    .open_title {
        width: 75px;
    }
    .open_val {
        flex: 1;
        color: #333;
    }    
}

.open_tip {
    font-size: 12px;
    line-height: 17px;
    color: #999999;
    margin-bottom: 50px;
}


.pdfCentent {
  min-height: 277mm;
  width: 180mm;
  background:#fff;
  font-size: 13px;
  margin:0 auto;
  color: #333;
  font-family: SimSun;
  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    margin:10px 0 20px;
    letter-spacing: 1px;
    color: #333;
  }

  .pdf_box {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 28px;
    color: #333;
  } 

  .zhang {
    position: relative;
    left: 70px; 
    top: -20px;
    height: 120px;
    width: 120px;
  }

  .pdf_table {
    border:2px solid #f2f2f2; 
    border-collapse:collapse; 
    table-layout: fixed;
    width: 100%;
    font-size: 12px;
    color: #333;
    font-weight: 400;
    margin: 10px 0 14px 0;
    tr {
      border-collapse:collapse; 
    }
    td {
      height: 30px;
      border:2px solid #f2f2f2; 
      text-align: center;
      border-collapse:collapse; 
    }
    .bg {
      background: #ebebeb;
    }
  }
}


</style>


<style lang="less">

.modal_open {
    width: 500px;
    height: 590px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #333333;
        }
    }
    .el-dialog__body{
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;

    }

    .dialog-footer {
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }

    .el-radio {
        height: auto;
    }

    .el-radio__input .el-radio__label {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #666666;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #013EAA;
        background: #013EAA;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #333;
        font-size: 13px;
    }
    
}
.modal_refund {
    width: 521px;
    height: 213px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body{
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-footer {
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }

    .el-radio {
        height: auto;
        margin-bottom: 26px;
    }

    .el-radio__input .el-radio__label,.el-radio__label{
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #666666;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #013EAA;
        background: #013EAA;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #333;
        font-size: 13px;
    }

    .open_check {
        margin-bottom: 0;
    }

}

.modal_any {
    width: 764px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body{
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-footer {
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;
            margin-top: 20px;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }

    .any_table {
        border: 1px solid #EAEAEA;
        margin-bottom: 24px;
        .any_title {
            height: 37px;
            line-height: 37px;
            padding: 0 18px;
        }
        .any_body {
            border-top: 1px solid #EAEAEA;
            max-height: 540px;
            overflow: auto;
            padding: 14px 26px 18px 21px;
        }

    }
    .any_reason {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        margin-bottom: 28px;
    }

    .li_list {
        margin-bottom: 28px;
        .info_order {
            width: 100%;
            border-bottom: none;
            .info_item {
                cursor: pointer;
                display: flex;
                width: 100%;
                align-items: center;

                .item_img {
                    margin-right: 24px;
                    width: 90px;
                    height: 90px;
                    flex-shrink: 0;
                }

                .right_item {
                    width: 100%;
                    font-size: 12px;
                    color: #333333;
                    line-height: 1;

                    .item_name {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        line-height: 22px;
                        font-size: 12px;
                        font-weight: 600;

                        span {
                            font-size: 10px;
                            color: #FFFFFF;
                            background: #559BFF;
                            opacity: 1;
                            border-radius: 2px;
                            padding: 1px 6px;
                            margin-left: 16px;
                        }
                    }

                    .item_type {
                        margin: 10px 0 10px;
                        color: #666;
                        b {
                            display: inline-block;
                            font-weight: normal;
                            //width: 60px;
                        }
                        span {
                            color: #999;
                            margin-left: 6px;
                        }
                    }

                    .item_money_count {
                        color: #1C46B7;
                    }
                }
            }

            .info_text {
                text-align: right;
                font-size: 12px;
                color: #666;
                line-height: 17px;
                width: 100px;
            }
            .txt_num {
                margin-bottom: 8px;
                color: #999;
            }
            .txt_buy {
                font-size: 12px;
                line-height: 17px;
                color: #1C46B7;
                text-decoration: underline;
                width: 80px;
                cursor: pointer;
            }
        }

        .info_logistics {
            padding: 19px 15px;
            width: 140px;
            text-align: center;
            flex-shrink: 0;

            .no_shipments {
                font-size: 12px;
                color: #999999;
            }

            .check_the_logistics {
                position: relative;

                .txt {
                    cursor: pointer;
                    font-size: 12px;
                    color: #c90416;
                    text-decoration: underline;
                }
            }
        }

        .logistics_box {
            // position: absolute;
            // top: 0;
            // left: 50%;
            // transform: translateX(-50%);
            padding: 0 16px 10px;
            width: 267px;
            height: 333px;
            background: #ffffff;
            border: 1px solid #f67322;
            border-radius: 1px;
            z-index: 2;

            .log_container {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .title {
                margin-bottom: 10px;
                padding: 17px 0 12px;
                font-size: 12px;
                font-weight: 700;
                color: #666666;
                text-align: left;
                border-bottom: 1px solid #eaeaea;
                }

                .log_info_ul {
                margin-right: -13px;
                padding-bottom: 42px;
                height: 100%;

                overflow: auto;

                .log_info_li {
                    display: flex;
                    margin: 0 0 15px;
                    text-align: left;

                    .left_dot {
                    margin-top: 4px;
                    margin-right: 10px;
                    width: 6px;
                    height: 6px;
                    background: #050505;
                    border-radius: 50%;
                    flex-shrink: 0;
                    }

                    .right_txt {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 17px;
                    color: #333333;
                    }
                }

                .active {
                    .left_dot {
                    background: #ff6c47;
                    }

                    .right_txt {
                    color: #ff6c47;
                    }
                }
                }
            }
        }
    }

    .li_list:last-child {
        margin-bottom: 0;
    }

    .el-radio {
        height: auto;
        margin-bottom: 26px;
    }

    .el-radio__input .el-radio__label,.el-radio__label{
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #666666;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #013EAA;
        background: #013EAA;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #333;
        font-size: 13px;
    }

    .open_check {
        margin-bottom: 0;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #013EAA;
        border-color: #013EAA;

    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #013EAA;
    }

}

.el-popover.el-popper.log_pop {
	padding: 0!important;
    width: 270px!important;
}

.logi_box {
    padding: 0 12px 12px;
    width: 270px;
    .log_title {
        padding: 17px 0 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        color: #666666;
        border-bottom: 1px solid #EAEAEA;
    }
    .log_list {
        height: 283px;
        width: 100%;
        overflow-x:hidden;
        overflow-y:auto;
        padding-right: 10px;
        li {
            position: relative;
            padding-left: 20px;
            margin-top:12px;
            .dian {
                position: absolute;
                left: 4px;
                top: 8px;
                width: 6px;
                height: 6px;
                background: #050505;
                border-radius: 50%;
            }
            .ctx {
                font-size: 12px;
                font-weight: 600;
                line-height: 17px;
                color: #333333;
            }
        }
        li:first-child {
            .dian {
                background: #fff;
            }
            .ctx {
                color: #1C46B7;
            }
        }
    }

}

</style>